import '../css/Features-Pricing.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Pricing() {
  const navigate = useNavigate(); // Initialize navigate function

  const handleGetStartedClick = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  const pricingPlans = [
    {
      title: 'Weekly',
      price: '$13/wk',
      features: [
        'Full access to all of our predictions',
        'Bet tracking tools and risk profile calculators',
        'Community support',
      ],
    },
    {
      title: 'Monthly',
      price: '$33/mo',
      features: [
        'Full access to all of our predictions',
        'Bet tracking tools and risk profile calculators',
        'Input into future sports added',
        'Community support',
      ],
      recommended: true, // Flag for recommended plan
    },
    {
      title: 'Yearly',
      price: '$330/yr',
      features: [
        'Full access to all of our predictions',
        'Bet tracking tools and risk profile calculators',
        'Input into future features and sports added',
        'Community support',
      ],
    },
  ];

  return (
    <section id="pricing" className="pricing-section">
      {pricingPlans.map((plan, index) => (
        <div key={index} className="pricing-card-wrapper">
          {/* recommended badge */}
          {plan.recommended && (
            <div className="badge"> Recommended </div>
          )}
        <div className={`pricing-card ${plan.recommended ? 'recommended' : ''}`} >
          {plan.recommended && (
            <>
              <div className="bg"></div>
              <div className="blob"></div>
            </>
          )}
          <h3>{plan.title}</h3>
          <strong>{plan.price}</strong>
          <ul>
            {plan.features.map((feature, i) => (
              <li key={i}>{feature}</li>
            ))}
          </ul>
          <button
            className="pricing-button"
            onClick={handleGetStartedClick}
          >
            SIGN UP
          </button>
        </div>
      </div>
      ))}
    </section>
  );
}

export default Pricing;
