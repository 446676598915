import React, { useEffect, useState } from 'react';
import {
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';

const PlayerGraph = ({ playerId, gameDate, benchmarkValue, betStatType, PlayerTeamID}) => {
    const [last5Games, setLast5Games] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch the last 5 games and prepare data for the graph
    useEffect(() => {
        if (!playerId || !gameDate) return;

        const fetchLast5Games = async () => {
            const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/player/last5games?playerId=${playerId}&gameDate=${gameDate}`;
            console.log(`${process.env.REACT_APP_BACKEND_URL}/player/last5games?playerId=${playerId}&gameDate=${gameDate}`);
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();

                // Transform data for Recharts
                const formattedData = result.map((game) => ({
                    GameDate: game.GameDate,
                    Performance: getYAxisValue(game, betStatType),
                    PlayerTeamID: game.PlayerTeamID,
                }));

                setLast5Games(formattedData);
            } catch (error) {
                console.error('Error fetching last 5 games:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLast5Games();
    }, [playerId, gameDate, benchmarkValue, betStatType, PlayerTeamID]);
    console.log(gameDate);

    // Helper to map betStatType to the appropriate game metric
    const getYAxisValue = (game, statType) => {
        if (statType.includes('disposals')) return game.Disposals;
        if (statType.includes('goals')) return game.Goals;
        if (statType.includes('kicks')) return game.Kicks;
        if (statType.includes('tackles')) return game.Tackles;
        if (statType.includes('fantasy')) return game.Fantasy;
        if (statType.includes('anytime')) return game.Goals;
        console.warn(`Unhandled betStatType: ${statType}`);
        return null;
    };

    const teamColors = {
        Sydney: '#D71920',
        Essendon: '#CC2031',
        Adelaide: '#AD7E00',
        Collingwood: '#3357FF',
        Western_Bulldogs: '#014896',
        Brisbane_Lions: '#75163D',
        Hawthorn: '#7F3709',
        Melbourne: '#323AB7',
        North_Melbourne: '#004AE5',
        Port_Adelaide: '#01B7E5',
        Greater_Western_Sydney: '#F15C22',
        St_Kilda: '#ED1C24',
        Gold_Coast: '#D71920',
        West_Coast: '#062EE2',
        Freemantle: '#2A2D81',
        Richmond: '#FBC33F',
        Geelong: '#2A70D8',
        Carlton: '#153F73',
    };

    const formatDateToDDMM = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}/${month}`;
    };

    const benchmark = benchmarkValue === 'N/A' ? 0 : parseFloat(benchmarkValue);

    const formatBetLineName = (name) => {
        // Replace underscores with spaces and capitalize each word
        return name
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
    };
    
    const getYAxisLimits = () => {
        const performanceValues = last5Games.map((game) => game.Performance || 0);
        const benchmarkValue = benchmark === 'N/A' ? 0 : parseFloat(benchmark); // Handle 'N/A' as 0
        const allValues = [...performanceValues, benchmarkValue]; // Include the benchmark in the scaling
        const max = Math.max(...allValues);
        const padding = max * 0.2; // Add 10% of max as padding
        return {
            min: 0, // Always start from 0
            max: Math.ceil(max + padding), // Add padding and round up
        };
    };
    

    return (
        <div style={{ height: '80%', width: '100%', padding: '0px 0px 0px 0px' }}>
            {loading ? (
                <p>Loading graph...</p>
            ) : last5Games ? (
                <>
                    {/* Dynamic Title */}
                    <h3 style={{ textAlign: 'center', color: '#cdcdcd', marginBottom: '10px'}}>
                        {`${formatBetLineName(betStatType)} (Last 5)`}
                    </h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={last5Games}>
                            {/* X-Axis */}
                            <XAxis
                                dataKey="GameDate"
                                tickFormatter={formatDateToDDMM}
                                tick={{ fontSize: 12, fill: '#fff' }}
                                stroke="#aaa"
                            />

                            {/* Y-Axis */}
                            <YAxis
                                domain={[getYAxisLimits().min, getYAxisLimits().max]} // Dynamic scaling
                                tickFormatter={(tick) => tick.toFixed(0)} // Format ticks
                                tick={{ fontSize: 12, fill: '#fff' }}
                                stroke="#aaa"
                            />

                            {/* Tooltip */}
                            <Tooltip
                                formatter={(value) => [`${value}`, 'Performance']}
                                labelFormatter={(date) => `Date: ${formatDateToDDMM(date)}`}
                                contentStyle={{
                                    backgroundColor: '#2b2b2b',
                                    borderRadius: '5px',
                                    color: '#fff',
                                }}
                            />

                            {/* Player Performance Line */}
                            <Line
                                type="monotone"
                                dataKey="Performance"
                                stroke={teamColors[last5Games[0]?.PlayerTeamID]}
                                strokeWidth={2}
                                activeDot={{ r: 8 }}
                            />

                            {/* Benchmark Line */}
                            <ReferenceLine
                                y={parseFloat(benchmarkValue)} // change this to 'benchmark' if we want to include anytime goals
                                label={{
                                    value: `${benchmarkValue}`, // stat line label
                                    position: 'insideTopRight',
                                    style: { fill: 'orange', fontSize: 12 },
                                }}
                                stroke="orange"
                                strokeDasharray="2 2"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </>
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

export default PlayerGraph;
