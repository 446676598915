import '../css/form-styles.css';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState(''); // For the username
  const [phone, setPhone] = useState('');       // For the phone number
  const [errorMessage, setErrorMessage] = useState(null);   // For error messages
  const [successMessage, setSuccessMessage] = useState(null); // For successful sign-up notification
  const navigate = useNavigate();

  // Step 1: Check if the username is unique
  const checkUsernameExists = async (username) => {
    const { data, error } = await supabase.rpc('check_username_exists', {
      username_input: username,
    });

    if (error) {
      console.error('Error checking username:', error.message);
      // If there's an error, we'll assume the username might be taken
      // to prevent collisions. Adjust behavior as needed.
      return true;
    }
    return data; // `data` will be true if username exists, false otherwise
  };

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^\d{8,15}$/;

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!username || !email || !password || !phone) {
      setErrorMessage('All fields are required.');
      return;
    }
  
    // Clear any previous messages
    setErrorMessage(null);
    setSuccessMessage(null);
  
    // 1. Validate Email
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
  
    // 2. Validate Phone
    if (!phoneRegex.test(phone)) {
      setErrorMessage('Please enter a valid phone number (digits only).');
      return;
    }
  
    // 3. Check if username is already taken
    const usernameExists = await checkUsernameExists(username);
    if (usernameExists) {
      setErrorMessage('Username is already taken. Please choose another.');
      return;
    }
  
    // 4. Sign up the user with Supabase
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });
  
      if (error) {
        if (error.message.includes('User already registered')) {
          setErrorMessage('Email is already registered. Please use a different email or log in.');
        } else {
          console.error('Error signing up:', error.message);
          setErrorMessage(error.message);
        }
        return;
      }

      // Store JWT Token & User ID
      localStorage.setItem("token", data.session.access_token);
      localStorage.setItem("userId", data.user.id);
      
  
      // 5. Insert the profile
      const { error: profileError } = await supabase.from('profiles').insert([
        { user_id: data.user.id, username, phone },
      ]);
  
      if (profileError) {
        if (profileError.message.includes('duplicate key value violates unique constraint')) {
          setErrorMessage('Username is already taken. Please choose another.');
        } else {
          console.error('Error inserting profile:', profileError.message);
          setErrorMessage(profileError.message);
        }
      } else {
        setSuccessMessage("Sign up successful! Redirecting you...");
        setTimeout(() => {
          navigate('/plans');
        }, 2000);
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    }
  };
  

  return (
    <div className="auth-form">
      <h2>Sign Up</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      <form onSubmit={handleSignup}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <input
          type="tel"
          placeholder="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
}

export default Signup;
