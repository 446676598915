import React, { useEffect, useState } from 'react';
import ProfitLossGraph from '../components/ProfitLossGraph';
import StatTypeRadarChart from '../components/RadarChart';
import RiskAnalysis from "../components/RiskAnalysis";
import Spinner from '../components/Spinner';
import TotalProfitLoss from '../components/TotalProfitLoss';
import UnitBetInput from '../components/UnitBetInput';
import WinLossPieChart from '../components/WinLossPieChart';
import '../css/PerformancePage.css';

import { supabase } from '../supabaseClient';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PerformancePage = () => {
    const [userData, setUserData] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [unitBet, setUnitBet] = useState(1); // Default unit bet amount
    const [userBets, setUserBets] = useState([]);
    const [averageStake, setAverageStake] = useState(0);
    const [filterType, setFilterType] = useState('lastWeek'); // This tracks the current filter (e.g., recent, all, etc.)
    const [filteredData, setFilteredData] = useState([]); // This stores the filtered data based on the selected filter

    

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await supabase.auth.getUser();
                const user = data.user; // Correctly get the user object
        
                if (user) {
                    const { data: profileData, error: profileError } = await supabase
                        .from('profiles')
                        .select('username, phone')
                        .eq('user_id', user.id)
                        .single();
        
                    if (profileError) throw profileError;
        
                    setUserData({
                        id: user.id,
                        username: profileData.username,
                        phone: profileData.phone,
                        email: user.email,
                    });
        
                    // console.log("User data set in state:", {
                    //     id: user.id,
                    //     username: profileData.username,
                    //     phone: profileData.phone,
                    //     email: user.email,
                    // });
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };

        const fetchPerformanceData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${BACKEND_URL}/api/results/user-results`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch performance data');
                }

                const result = await response.json();
                setData(result.data || []);
                setFilterData();
            } catch (error) {
                console.error('Error fetching performance data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
        fetchPerformanceData();
    }, [filterType]);


    useEffect(() => {
        const fetchUserBets = async () => {
            try {
                const { data: { user }, error: userError } = await supabase.auth.getUser();
                if (userError || !user) {
                    console.error('Error fetching user:', userError);
                    return;
                }

                const { data: bets, error } = await supabase
                    .from('userbets')
                    .select('unit_placed, bet_id')
                    .eq('user_id', user.id);

                if (error) {
                    console.error('Error fetching user bets:', error);
                    return;
                }

                const betsArray = (bets || []).filter(bet => bet.unit_placed !== null);
                //console.log("Filtered bets (excluding nulls):", betsArray);
                setUserBets(betsArray);
                
                // Compute average stake safely
                if (betsArray.length > 0) {
                    const sum = betsArray.reduce((acc, b) => acc + (b.unit_placed ?? 0), 0);
                    setAverageStake(sum / betsArray.length);
                } else {
                    setAverageStake(0);
                }
            } catch (err) {
                console.error('Unexpected error:', err);
            }
        };
        console.log('test', data);

        fetchUserBets();
    }, []);

    // Helper function to get date range
    const getDateRange = (filterType) => {
        const today = new Date();
        let startDate = null;

        switch (filterType) {
            case 'yesterday':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 1);
                break;
            case 'lastWeek':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 7);
                break;
            case 'last30Days':
                startDate = new Date(today);
                startDate.setDate(today.getDate() - 30);
                break;
            default:
                startDate = new Date('2025-01-01'); // all-time, no filtering
                break;
        }

        return startDate;
    };

    const setFilterData = () => {
        const startDate = getDateRange(filterType); // Get the correct start date based on filterType
        
        const filtered = data.filter(bet => {
          const betDate = new Date(bet.game_date); // Ensure game_date is a valid date string or timestamp
          const included = (betDate >= startDate && betDate <= new Date()); 
          if (included) {
            console.log(
              'Included bet in filter:',
              bet,
              '\n betDate:',
              bet.game_date,
              '\n startDate:',
              startDate,
              '\n now:',
              new Date()
            );
          }
          return included;
        });
        
        setFilteredData(filtered); // Store the filtered data
      };

    useEffect(() => {
        if (data.length > 0) {
            setFilterData(); // Automatically filter data based on the default filterType
        }
    }, [filterType, data]); // Re-run this effect whenever filterType or data changes
    


    return (
        <div className="performance-page">
            <div className="top-row">
                <div className="performance-card dateRange">
                    <button
                        onClick={() => setFilterType("yesterday")}
                        style={{
                            backgroundColor: filterType === "yesterday" ? "green" : "gray",
                        }}
                    >
                        Yesterday
                    </button>
                    <button
                        onClick={() => setFilterType("lastWeek")}
                        style={{
                            backgroundColor: filterType === "lastWeek" ? "green" : "gray",
                        }}
                    >
                        Last Week
                    </button>
                    <button
                        onClick={() => setFilterType("last30Days")}
                        style={{
                            backgroundColor: filterType === "last30Days" ? "green" : "gray",
                        }}
                    >
                        Last 30 Days
                    </button>
                    <button
                        onClick={() => setFilterType("all")}
                        style={{
                            backgroundColor: filterType === "all" ? "green" : "gray",
                        }}
                    >
                        All Time
                    </button>
                </div>
                {/* <div className="performance-card welcome-box">
                    {userData && <WelcomeMessage username={userData.username} />}
                </div> */}
                <div className="performance-card profit-tile-container">
                    <TotalProfitLoss data={filteredData} userBets={userBets} />
                </div>
                <div className="performance-card unit-bet-box">
                    <UnitBetInput averageStake={averageStake} />
                </div>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <div className="performance-content">
                    <div className="performance-card graph-container">
                        <ProfitLossGraph data={filteredData} userBets={userBets} filterType={filterType} />
                    </div>
                    <div className="performance-card pie-chart-container">
                        <WinLossPieChart data={filteredData} />
                    </div>
                    <div className="performance-card radar-chart-container">
                        <StatTypeRadarChart data={filteredData} />
                    </div>
                    <div className="performance-card risk-analysis-container">
                        <RiskAnalysis data={filteredData} userBets={userBets}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PerformancePage;