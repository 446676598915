import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import '../css/resetPassword.css';
import { useNavigate } from 'react-router-dom';


const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [status, setStatus] = useState(null);

    const navigate = useNavigate();

    const handleResetPassword = async () => {
        if (password !== confirmPassword) {
            setStatus('Passwords do not match.');
            return;
        }

        try {
            // Use Supabase's `updateUser` method to update the password
            const { error } = await supabase.auth.updateUser({ password });

            if (error) {
                setStatus('Failed to reset password: ' + error.message);
            } else {
                setStatus('Password reset successfully! Redirecting...');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
        } catch (err) {
            setStatus('An error occurred. Please try again.');
        }
    };

    return (
        < div class="reset-password-container">
        <h2>Reset Password</h2>
        <form>
            <div>
                <input 
                    type="password" 
                    id="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div>
                <input
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </div>
            <div class='reset-button'>
                <button onClick={handleResetPassword}>Reset Password</button>
            </div>
        </form>
        {status && <p>{status}</p>}
        <div class="reset-link">
            <a href="/">Go back to Login</a>
        </div>
        </div>
                
    );
};

export default ResetPassword;