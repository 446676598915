import React from "react";
import "../css/Performance/RiskAnalysis.css";

const RiskAnalysis = ({ data, userBets }) => {
    // console.log(data)
    console.log("userBets in RiskAnalysis:", userBets);

    const userBetsMap = userBets.reduce((acc, ub) => {
        acc[ub.bet_id] = ub.unit_placed;
        return acc;
      }, {});

    // Aggregate data into risk bands
    const riskBands = {
        high: { bets: 0, wins: 0, totalWagered: 0, totalPayout: 0, marketCounts: {} },
        medium: { bets: 0, wins: 0, totalWagered: 0, totalPayout: 0, marketCounts: {} },
        low: { bets: 0, wins: 0, totalWagered: 0, totalPayout: 0, marketCounts: {} },
    };

    let totalPrediction = 0;

    data.forEach((bet) => {
        const { bet_id, model_probability, outcome, odds, stat_type } = bet;

        const normalizedOutcome = outcome ? outcome.trim().toLowerCase() : '';
        
        // Skip if outcome is empty or "pending"
        if (!normalizedOutcome || normalizedOutcome === 'pending') {
            return;
        }

        totalPrediction += model_probability;
    
        // Determine risk band
        let band = null;
        if (model_probability < 65) band = "high";
        else if (model_probability < 80) band = "medium";
        else band = "low";
    
        if (band) {
          riskBands[band].bets += 1;
    
          // 3) Match stake from userBetsMap
          const stake = parseFloat(userBetsMap[bet_id] ?? 0);
          riskBands[band].totalWagered += stake;
    
          if (outcome === "yes") {
            riskBands[band].wins += 1;
            // Payout = odds * stake for a winning bet
            riskBands[band].totalPayout += parseFloat(odds) * stake;
          }
          riskBands[band].marketCounts[stat_type] =
            (riskBands[band].marketCounts[stat_type] || 0) + 1;
        }
      });

    // Filter data to include only bets with a valid (non‑pending) outcome
    const validBets = data.filter(bet => {
        const outcome = bet.outcome ? bet.outcome.trim().toLowerCase() : '';
        return outcome && outcome !== 'pending';
    });
    
    // Now compute average prediction using only valid bets
    const averagePrediction = validBets.length > 0 
        ? (totalPrediction / validBets.length).toFixed(2) 
        : "0.00";

    // Calculate ROI and most common markets for each risk band.
    const formatBand = (band) => {
        const { bets, wins, totalWagered, totalPayout, marketCounts } = band;
      
        // Compute win rate
        const winRate = bets ? ((wins / bets) * 100).toFixed(2) : 0;
      
        // Compute ROI
        let rawROI = 0;
        if (totalWagered > 0) {
          rawROI = ((totalPayout - totalWagered) / totalWagered) * 100;
        }
      
        // Convert rawROI to a string with 2 decimal places
        const roi = parseFloat(rawROI).toFixed(2);
      
        // Most common market
        const mostCommonMarket = Object.entries(marketCounts)
          .sort(([, a], [, b]) => b - a)[0]?.[0]?.replaceAll("_", " ") || "N/A";
      
        return { bets, winRate, roi, mostCommonMarket };
      };
    
      const highRisk = formatBand(riskBands.high);
      const mediumRisk = formatBand(riskBands.medium);
      const lowRisk = formatBand(riskBands.low);

    return (
        <div className="risk-analysis-container">
            <h2 className="risk-analysis-title">Risk Analysis</h2>
            <p className="average-prediction">
                Average Prediction: <strong>{averagePrediction}%</strong>
            </p>
            <div className="risk-band">
                <h3 className="risk-band-title high-risk">High Risk</h3>
                <p>{highRisk.bets} bets</p>
                <p>Win Rate: {highRisk.winRate}%</p>
                <p>ROI: {highRisk.roi}%</p>
                <p>Most Common Market: {highRisk.mostCommonMarket}</p>
            </div>
            <div className="risk-band">
                <h3 className="risk-band-title medium-risk">Medium Risk</h3>
                <p>{mediumRisk.bets} bets</p>
                <p>Win Rate: {mediumRisk.winRate}%</p>
                <p>ROI: {mediumRisk.roi}%</p>
                <p>Most Common Market: {mediumRisk.mostCommonMarket}</p>
            </div>
            <div className="risk-band">
                <h3 className="risk-band-title low-risk">Low Risk</h3>
                <p>{lowRisk.bets} bets</p>
                <p>Win Rate: {lowRisk.winRate}%</p>
                <p>ROI: {lowRisk.roi}%</p>
                <p>Most Common Market: {lowRisk.mostCommonMarket}</p>
            </div>
        </div>
    );
};

export default RiskAnalysis;