import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EOI from "../components/EOI";
import FilterMenu from '../components/FilterMenu';
import GameTile from '../components/GameTile';
import Spinner from '../components/Spinner';
import Tile from '../components/Tile';
import '../css/Home.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function HomePage() {
    const navigate = useNavigate(); // for redirects
    const [showEOI, setShowEOI] = useState(false); // State to control the EOI popup visibility

    // Data and display state
    const [tilesData, setTilesData] = useState([]);
    const [displayCount, setDisplayCount] = useState(0);
    const [expandedTileIndex, setExpandedTileIndex] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null);
    
    // Submenu and Filter Menu visibility
    const [selectedSubmenu, setSelectedSubmenu] = useState('gamePicks'); // 'gamePicks' or 'playerPicks'
    const [filterMenuVisible, setFilterMenuVisible] = useState(false);

    // handles dropdown to select sport on the navbar 
    const [selectedLeague, setSelectedLeague] = useState("AFL");
    const [dropdownVisible, setDropdownVisible] = useState(false);

    // Filter state values (default values are empty except for the sport)
    const [selectedGame, setSelectedGame] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSport, setSelectedSport] = useState('AFL'); // Default is AFL
    const [minAdvantage, setMinAdvantage] = useState('');
    const [maxAdvantage, setMaxAdvantage] = useState('');
    const [minOdds, setMinOdds] = useState('');
    const [maxOdds, setMaxOdds] = useState('');
    const [games, setGames] = useState([]);

    const [isSubmenuVisible, setIsSubmenuVisible] = useState(true);
    const lastScrollY = useRef(window.scrollY);

    // Initialised variables for management of selected bet buttons on home page
    const [selectedBets, setSelectedBets] = useState(new Set()); // Store selected bets
    const userId = localStorage.getItem("userId"); // Get user ID

    useEffect(() => {
        const hasSeenEOI = localStorage.getItem('hasSeenEOI');
        if (!hasSeenEOI) {
          const timer = setTimeout(() => {
            setShowEOI(true);
            localStorage.setItem('hasSeenEOI', 'true');
          }, 2000);
          return () => clearTimeout(timer);
        }
      }, []);

    // Function to calculate the number of visible tiles based on container width
    const calculateVisibleTiles = () => {
        const container = document.querySelector('.tiles-container');
        if (container) {
            const containerWidth = container.offsetWidth;
            const minTileWidth = 276; // Adjust if needed
            const tilesPerRow = Math.floor((containerWidth + 10) / minTileWidth);
            const rowsToShow = 2; // Show 2 rows initially
            return tilesPerRow * rowsToShow;
        }
        return 5; // Fallback value
    };


    useEffect(() => {
        const updateButtonBets = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) return;
    
                const response = await fetch(`${BACKEND_URL}/api/user-bets/update`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                if (!response.ok) {
                    console.error("Error fetching user bets:", await response.text());
                    return;
                }
    
                const data = await response.json();
                console.log(data);
                setSelectedBets(new Set(data.bets)); // Store as a Set for fast lookup
            } catch (error) {
                console.error("Error fetching user bets:", error);
            }
        };
    
        updateButtonBets();
    }, []);

    // Fetch relevant games for the filter menu based on the selected sport
    useEffect(() => {
        console.log('Fetching games for sport:', selectedSport);
        fetch(`${BACKEND_URL}/api/games/relevant-games?sport=${selectedSport}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Fetched games:', data);
                setGames(data || []);
            })
            .catch((error) => console.error('Error fetching relevant games:', error));
    }, [selectedSport]);

    // Fetch games data for the dropdown menu (again including the sport parameter)
    useEffect(() => {
        console.log("Fetching games for the filter menu...");
        fetch(`${BACKEND_URL}/betting/games?sport=${selectedSport}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok");
                return response.json();
            })
            .then((data) => setGames(data))
            .catch((error) => console.error("Error fetching games:", error));
    }, [selectedSport]);


    // Fetch Player Picks data (including the selected sport)
    const fetchPlayerPicksData = (filters = {}) => {
        console.log('Fetching Player Picks Data with filters:', filters);
        console.log('Selected Sport:', selectedSport); // Debugging: Check if it's AFL or NRL
    
        const {
            game = '',
            date = '',
            minAdvantage = '',
            maxAdvantage = '',
            minOdds = '',
            maxOdds = ''
        } = filters;
    
        let homeTeamID = '';
        let awayTeamID = '';
    
        if (game && game.includes(' vs ')) {
            [homeTeamID, awayTeamID] = game.split(' vs ');
        } else if (game) {
            console.error(`Invalid game format: ${game}`);
        }
    
        const params = new URLSearchParams({
            sport: selectedSport,  // Make sure this is updating
            home_team_id: homeTeamID || '',
            away_team_id: awayTeamID || '',
            selected_date: date || '',
            min_advantage: minAdvantage ? parseFloat(minAdvantage) : '',
            max_advantage: maxAdvantage ? parseFloat(maxAdvantage) : '',
            min_odds: minOdds ? parseFloat(minOdds) : '',
            max_odds: maxOdds ? parseFloat(maxOdds) : '',
        });
    
        console.log('Player Picks Params:', params.toString());
    
        // Conditionally select endpoint based on selectedLeague
        const endpoint = selectedLeague === "NRL" 
        ? '/betting/nrl-player-picks' 
        : '/betting/player-best-odds';

        console.log(`Fetching from ${selectedSport} Endpoint:`, `${BACKEND_URL}${endpoint}`);
    
        setLoading(true);
        fetch(`${BACKEND_URL}${endpoint}?${params.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(async (response) => {
                if (!response.ok) {
                    if (response.status === 403) {
                        navigate('/plans');
                    }
                    const text = await response.text();
                    console.error('Player Picks Response Error:', text);
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Received Player Picks Data:', data);
                setTilesData(data || []);
            })
            .catch((error) => console.error('Error fetching Player Picks Data:', error))
            .finally(() => setLoading(false));
    };

    
    // Fetch Game Picks data (including the selected sport)
    const fetchGamePicksData = (filters = {}) => {
        console.log('Fetching Game Picks Data with filters:', filters);
    
        const {
            game = '',
            date = '',
            minAdvantage = '',
            maxAdvantage = '',
            minOdds = '',
            maxOdds = ''
        } = filters;
    
        let homeTeamID = '';
        let awayTeamID = '';
    
        if (game && game.includes(' vs ')) {
            [homeTeamID, awayTeamID] = game.split(' vs ');
        } else if (game) {
            console.error(`Invalid game format: ${game}`);
        }
    
        const params = new URLSearchParams({
            selected_date: date || '',
            home_team_id: homeTeamID || '',
            away_team_id: awayTeamID || '',
            min_advantage: minAdvantage ? parseFloat(minAdvantage) : '',
            max_advantage: maxAdvantage ? parseFloat(maxAdvantage) : '',
            min_odds: minOdds ? parseFloat(minOdds) : '',
            max_odds: maxOdds ? parseFloat(maxOdds) : '',
        });
    
        console.log('Game Picks Params:', params.toString());
    
        const endpoint = selectedLeague === "NRL" ? '/betting/nrl-game-picks' : '/betting/ranked-team-bets';
        console.log(`Fetching from ${selectedSport} Endpoint:`, `${BACKEND_URL}${endpoint}`);
    
        setLoading(true);
        fetch(`${BACKEND_URL}${endpoint}?${params.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(async (response) => {
                if (!response.ok) {
                  if (response.status === 403) {
                    navigate('/plans');
                  }
                  const text = await response.text();
                  console.error('Game Picks Response Error:', text);
                  throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Game Picks Data:', data);
                setTilesData(data || []);
            })
            .catch((error) => console.error('Error fetching Game Picks Data:', error))
            .finally(() => setLoading(false));
    };
    
    // Refetch data whenever filters or sport changes
    useEffect(() => {
        if (selectedSubmenu === 'playerPicks') {
            fetchPlayerPicksData();
        } else if (selectedSubmenu === 'gamePicks') {
            fetchGamePicksData();
        }
    }, [selectedSubmenu, selectedGame, selectedDate, minAdvantage, maxAdvantage, minOdds, maxOdds, selectedLeague]);

    // Adjust tile display on window resize
    useEffect(() => {
        const initialTiles = calculateVisibleTiles();
        setDisplayCount(initialTiles);

        const handleResize = () => {
            const visibleTiles = calculateVisibleTiles();
            setDisplayCount(prev => Math.max(prev, visibleTiles));
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const handleSubmenuChange = (submenu) => {
        setSelectedSubmenu(submenu);
    };

    const toggleFilterMenu = () => {
        setFilterMenuVisible(prev => !prev);
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const leagueLogos = {
        AFL: "/images/AFL/afl.png",
        NRL: "/images/NRL/NRL New.png",
    };
    
    const handleLeagueChange = (league) => {
        console.log(`Switching league to: ${league}`); // Debugging log
        setSelectedLeague(league);
        setSelectedSport(league);
        setDropdownVisible(false); // Hide dropdown after selection
    };

    useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
    
          if (currentScrollY <= 10) {
            setIsSubmenuVisible(true); 
          } else if (currentScrollY > lastScrollY.current) {
            setIsSubmenuVisible(false);
          } else {
            setIsSubmenuVisible(true);
          }
    
          lastScrollY.current = currentScrollY;
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

      // used to pull the most recent "last_updated variable"
      useEffect(() => {
        setLoading(true);
        fetch(`${BACKEND_URL}/betting/nrl-game-picks`)
            .then((response) => response.json())
            .then((data) => {
                console.log("Full API response:", data);
                // Then process to extract last_updated values.
                const dates = data.map((item) => new Date(item.last_updated)).filter((d) => !isNaN(d));
                if (dates.length > 0) {
                    const mostRecent = new Date(Math.max(...dates));
                    setLastUpdated(mostRecent);
                    console.log("Most recent last_updated:", mostRecent.toLocaleString());
                }
                setTilesData(data || []);
            })
            .catch((err) => console.error("Error fetching data:", err))
            .finally(() => setLoading(false));
                }, [BACKEND_URL]);


    return (
        <>
        <div className={`landing-container ${showEOI ? "blur-background" : ""}`}>
            <div className="home-container">
                {/* Submenu Bar */}
                <div className={`submenu-bar ${isSubmenuVisible ? "visible" : "hidden"}`}>

                    {/* { Dropdown for league selection } */}
                    {/* Left Side: League Dropdown */}
                    <div className="left-section">
                        <div className="league-dropdown">
                            <button onClick={toggleDropdown} className="dropdown-button">
                                <img src={leagueLogos[selectedLeague]} alt={selectedLeague} className="league-logo" />
                                <span className="dropdown-arrow">▼</span>
                            </button>
                            {dropdownVisible && (
                                <div className="dropdown-menu">
                                    <div className="dropdown-item" onClick={() => handleLeagueChange('AFL')}>AFL</div>
                                    <div className="dropdown-item" onClick={() => handleLeagueChange('NRL')}>NRL</div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Center: Game Picks & Player Picks */}
                    <div className="submenu-items">
                        <div className={`submenu-item ${selectedSubmenu === 'gamePicks' ? 'active' : ''}`}
                            onClick={() => handleSubmenuChange('gamePicks')}>
                            Game Picks
                        </div>
                        <div className={`submenu-item ${selectedSubmenu === 'playerPicks' ? 'active' : ''}`}
                            onClick={() => handleSubmenuChange('playerPicks')}>
                            Player Picks
                        </div>
                    </div>

                    {/* Right Side: Filter Button */}
                    <div className="filter-trigger" onClick={toggleFilterMenu}>
                        {filterMenuVisible ? '▲ Filter' : '▼ Filter'}
                    </div>
                </div>

                {/* Filter Menu Dropdown */}
                {filterMenuVisible && (
                    <div className="filter-menu-container">
                        <FilterMenu
                            games={games}
                            selectedGame={selectedGame}
                            setSelectedGame={setSelectedGame}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            minAdvantage={minAdvantage}
                            setMinAdvantage={setMinAdvantage}
                            maxAdvantage={maxAdvantage}
                            setMaxAdvantage={setMaxAdvantage}
                            minOdds={minOdds}
                            setMinOdds={setMinOdds}
                            maxOdds={maxOdds}
                            setMaxOdds={setMaxOdds}
                            onSubmitFilters={(filters) => {
                                if (selectedSubmenu === 'playerPicks') {
                                    fetchPlayerPicksData(filters);
                                } else {
                                    fetchGamePicksData(filters);
                                }
                            }}
                        />
                    </div>
                )}

                {loading ? (
                    <Spinner />
                ) : (
                    <div className="tiles-wrapper">
                        <div className='last-updated'>
                            <p>
                            Last Updated:{" "}
                            {lastUpdated ? lastUpdated.toLocaleString() : "N/A"}
                            </p>
                        </div>
                        <div className="tiles-container">
                            {/* Show message if no data is available for Player Picks or Game Picks */}
                            {tilesData.length === 0 ? (
                                <p className="no-data-message">
                                        No picks available at this time. Please check back later.
                                </p>
                            ) : (
                                tilesData.slice(0, displayCount).map((tileData, index) => {
                                    console.log('Tile Data:', tileData);
                                    return selectedSubmenu === 'playerPicks' ? (
                                        <Tile
                                            key={index}
                                            data={tileData}
                                            index={index}
                                            expandedIndex={expandedTileIndex}
                                            onExpand={() => setExpandedTileIndex(index)}
                                            selectedBets={selectedBets} // Pass state correctly
                                            setSelectedBets={setSelectedBets} // Pass state updater
                                        />
                                    ) : (
                                        <GameTile
                                            key={index}
                                            data={tileData}
                                            index={index}
                                            expandedIndex={expandedTileIndex}
                                            onExpand={() => setExpandedTileIndex(index)}
                                            selectedSport={selectedSport}
                                            selectedBets={selectedBets} // Pass state correctly
                                            setSelectedBets={setSelectedBets} // Pass state updater
                                        />
                                    );
                                })
                            )}
                        </div>
                    </div>
                )}

                {!loading && displayCount < tilesData.length && (
                    <button
                        onClick={() => setDisplayCount(prev => prev + 10)}
                        className="load-more-button"
                    >
                        Load More
                    </button>
                )}
            </div>
        </div>
        {/* Render EOI popup if applicable */}
        {showEOI && <EOI setShowPopup={setShowEOI} />}
        </>
        
    );
}

export default HomePage;
