import React, { useState } from 'react';
import '../css/BetModal.css'; // We'll add minimal CSS in a moment

function BetModal({ onConfirm, onCancel }) {
    const [betAmount, setBetAmount] = useState('');
  
    const handleConfirmClick = () => {
      // Pass the typed amount back up to the parent
      onConfirm(betAmount);
    };
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>How much are you placing on this bet?</h2>
          <input
            type="number"
            value={betAmount}
            onChange={(e) => setBetAmount(e.target.value)}
            placeholder="Enter amount"
          />
          <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleConfirmClick}>Confirm</button>
              <button className="modal-cancel" onClick={onCancel}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }
  
  export default BetModal;