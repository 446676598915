import React, { useEffect, useState } from "react";
import {
    Bar,
    BarChart,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import '../../css/Results.css';

const MLProfitabilityGraph = ({ resultsData, selectedSport }) => {
    const [isMobile, setIsMobile] = useState(false);  // To detect mobile view

    const filterBySport = (sport) => {
        return resultsData.filter((bet) =>
            sport === "NRL" ? bet.table.includes("nrl") : bet.table.includes("TeamBets") || bet.table.includes("PlayerOdds")
        );
    };

    // Sample bet data mapping by sport type (NRL and AFL)
    const sportBetLines = {
        NRL: {
            "player disposals over": "#a6d854",
            "player goal scorer anytime": "#e8a838",
            "spreads": "#61cdbb",
            "h2h": "#f47560",
        },
        AFL: {
            "player disposals over": "#3498db",
            "player goal scorer anytime": "#9b59b6",
            "spreads": "#1abc9c",
            "h2h": "#e74c3c",
        }
    };

    // Mobile detection logic
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // You can change 768 to your desired mobile breakpoint
        };

        window.addEventListener("resize", handleResize);
        handleResize();  // Check initial window size

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const statTypeColorMap = {
        "player disposals over": "#a6d854",
        "player goal scorer anytime": "#e8a838",
        "spreads": "#61cdbb",
        "h2h": "#f47560",
    };

    if (!resultsData.length) return (
        <div className="graphTileBorder">
            <div className="emptyTile">
                <div>
                    <p>No data for this date range</p>
                </div>
            </div>
        </div>
    )

    const getColorForStatType = (statType) => {
        return statTypeColorMap[statType] || "#888888"; // fallback color
    };

    // Get filtered data based on the selected sport
    const filteredData = filterBySport(selectedSport);

    const groupedData = {};
    filteredData.forEach((bet) => {
        const betType = bet.StatType;
        const profit = bet.WinLoss === "yes" ? (bet.OddsValue - 1) : -1; // 1 unit bet

        if (!groupedData[betType]) {
            groupedData[betType] = { betType: betType.replace(/_/g, " "), totalProfit: 0 };
        }

        groupedData[betType].totalProfit += profit;
    });

    console.log(groupedData);

    const formattedData = Object.values(groupedData).map((entry) => ({
        betType: entry.betType,
        profit: entry.totalProfit.toFixed(2)
    }));

    // Calculate the min and max profit
    const minProfit = Math.min(...formattedData.map(entry => parseFloat(entry.profit)));
    const maxProfit = Math.max(...formattedData.map(entry => parseFloat(entry.profit)));

    // If all profits are positive, start from 0
    const adjustedMinProfit = minProfit < 0 ? minProfit : 0;
    
    return (
        <div className="graphTileBorder">
            <div className="graphTile">
                <div className="tileHeader">
                    <h2>Unit Profit</h2>
                </div>
                <div className="offsetGraphs">
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={formattedData} layout="vertical">
                        <XAxis type="number" domain={[adjustedMinProfit, maxProfit]} />
                        <YAxis
                            dataKey="betType"
                            type="category"
                            width={200}
                            tick={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                        />
                        <Tooltip />
                        <Bar dataKey="profit" name="Profit (Units)">
                            {formattedData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={getColorForStatType(entry.betType)}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default MLProfitabilityGraph;
