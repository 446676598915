import '../css/Navbar.css';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../supabaseClient';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  // Refs for the main nav and the user dropdown
  const menuRef = useRef(null);
  const menuIconRef = useRef(null);
  const userMenuRef = useRef(null);
  const userIconRef = useRef(null);

  const isLoggedIn = Boolean(user);

  // Watch supabase auth changes
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user || null);
      }
    );
    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, [setUser]);

  // Toggle main nav
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // Toggle user dropdown
  const toggleUserMenu = () => {
    setIsUserMenuOpen((prev) => !prev);
  };

  // Close the menu on link click
  const handleMenuClick = () => {
    setIsMenuOpen(false);
  };

  // Sign out
  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error.message);
    } else {
      setUser(null);
      setIsUserMenuOpen(false);
      localStorage.removeItem('token');
      navigate('/login');
    }
  };

  // Close menus if clicking anywhere outside
  useEffect(() => {
    function handleClickOutside(event) {
      // Check if click is outside the main nav (menuRef)
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        menuIconRef.current && // ✓ Check if the click target is within the hamburger icon
        !menuIconRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }


      // Also check if click is outside the user dropdown & user icon
      const userMenuClicked =
        userMenuRef.current && userMenuRef.current.contains(event.target);
      const userIconClicked =
        userIconRef.current && userIconRef.current.contains(event.target);

      if (!userMenuClicked && !userIconClicked) {
        setIsUserMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header>
      <div className="navbar">
        {/* Hamburger Menu Icon */}
        <div className="menu-icon" onClick={toggleMenu} ref={menuIconRef} >
          ☰
        </div>

        {/* Centered Logo */}
        <div className="logo">
          <Link to="/">
            <img src="/images/logo.png" alt="Moolah logo" className="logo-full" />
            <img src="/images/icon.jpg" alt="Moolah icon" className="logo-icon" />
          </Link>
        </div>

        {/* Full-Screen Nav Links */}
        <ul
          className={`nav-links ${isMenuOpen ? 'active' : ''}`}
          ref={menuRef} // Attach the main menu ref
        >
          {!user ? (
            <>
              <li><Link to="/?section=about" onClick={handleMenuClick}>About</Link></li>
              <li><Link to="/?section=features" onClick={handleMenuClick}>Features</Link></li>
              <li><Link to="/?section=pricing" onClick={handleMenuClick}>Pricing</Link></li>
              <li><Link to="/results" onClick={handleMenuClick}>Results</Link></li>
              <li className="mobile-only">
                <Link to="/login" className="login-btn" onClick={handleMenuClick}>
                  Login
                </Link>
              </li>
            </>
          ) : (
            <>
              <li><Link to="/home" onClick={handleMenuClick}>Picks</Link></li>
              <li><Link to="/user-results" onClick={handleMenuClick}>My Results</Link></li>
              <li><Link to="/performance" onClick={handleMenuClick}>Performance</Link></li>
              <li><Link to="/multi-creator" onClick={handleMenuClick}>Multi Creator</Link></li>
            </>
          )}
        </ul>

        {/* Auth Buttons for Desktop */}
        <div className="auth-buttons">
          {!isLoggedIn ? (
            <>
              <Link to="/login" className="login-btn desktop-only">Login</Link>
              <Link to="/signup" className="signup-btn">Sign Up</Link>
            </>
          ) : (
            <>
              {/* Ref for the user icon */}
              <div className="user-icon" onClick={toggleUserMenu} ref={userIconRef}>
                <InitialsAvatar
                  name={user?.user_metadata?.full_name || user.email}
                  className="initials-avatar"
                />
              </div>
              {isUserMenuOpen && (
                <ul className="user-dropdown" ref={userMenuRef}>
                  <li>
                    <Link to="/settings" onClick={() => setIsUserMenuOpen(false)}>
                      Settings
                    </Link>
                  </li>
                  <li>
                    <button onClick={handleSignOut} className="sign-out-btn">
                      Sign Out
                    </button>
                  </li>
                </ul>
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Navbar;
