import React from 'react';
import '../css/Performance/UnitBetInput.css';

const AverageBetDisplay = ({ averageStake }) => {
    return (
      <div className="unit-bet-container">
        <p>{`Average Stake: $${(averageStake ?? 0).toFixed(2)}`}</p>
      </div>
    );
  };
  
  export default AverageBetDisplay;