import React from 'react';
import '../css/Performance/TotalProfitLoss.css';


const TotalProfitLoss = ({ data, userBets }) => {
    // Create a map of bet_id -> unit_placed
    const userBetsMap = userBets.reduce((acc, ub) => {
      acc[ub.bet_id] = ub.unit_placed;
      return acc;
    }, {});
  
    // Calculate profit/loss for each bet
    const betProfitLosses = data.map((bet) => {
        const { bet_id, outcome, odds } = bet;
      
        // stake from userBetsMap
        const stake = parseFloat(userBetsMap[bet_id] ?? 0);
      
        let profitLoss = 0;
        if (outcome === 'yes') {
          // Win: (odds - 1) * stake
          profitLoss = (parseFloat(odds) - 1) * stake;
        } else if (outcome === 'no') {
          profitLoss = -stake;
        } else if (outcome === 'pending') {
          profitLoss = 0;
        }
      
        return {
          bet_id,
          stake,
          outcome,
          odds: parseFloat(odds),
          profitLoss,
        };
      });
  
    // Compute total profit/loss
    const totalProfitLoss = data.reduce((acc, bet) => {
        const { bet_id, outcome, odds } = bet;
        const stake = parseFloat(userBetsMap[bet_id] ?? 0);
      
        // Normalize outcome
        const normalizedOutcome = outcome ? outcome.trim().toLowerCase() : '';
      
        // Skip if outcome is empty, null, or 'pending'
        if (!normalizedOutcome || normalizedOutcome === 'pending') {
          return acc; // do not add anything to the total
        }
      
        let profitLoss = 0;
        if (normalizedOutcome === 'yes') {
          profitLoss = (parseFloat(odds) - 1) * stake;
        } else if (normalizedOutcome === 'no') {
          profitLoss = -stake;
        }
      
        console.log(
          `Bet ${bet_id}: outcome=${outcome}, odds=${odds}, stake=${stake}, profitLoss=${profitLoss}`
        );
      
        return acc + profitLoss;
      }, 0);
  
    // Helper to format numbers
    const formatNumber = (num) => num.toLocaleString(undefined, { minimumFractionDigits: 2 });
  
    return (
      <div className="total-profit-loss">
        <div className="profit-icon">
          <img src="/icon.jpg" alt="Logo Icon" className="logo-icon" />
        </div>
        <div className="profit-details">
        <span className="profit-label">Profit:</span>
        <span className={`profit-amount ${totalProfitLoss >= 0 ? 'positive' : 'negative'}`}>
            {`${formatNumber(totalProfitLoss)}`}
            </span>
          </div>
        </div>
    );
  };
  
  export default TotalProfitLoss;