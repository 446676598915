import React from 'react';
import { getTeamLogo, getBookieLogo } from '../../utils/logoHelpers';

const weatherIcons = {
    "Clear": "/images/weather/clear-day.svg",
    "Cloudy": "/images/weather/cloudy.svg",
    "Drizzle": "images/weather/drizzle.svg",
    "Rain": "/images/weather/rain.svg"
}

const getDisplayTeamName = (teamName) => {
    return teamName === "Greater Western Sydney" ? "GWS" : teamName;
};

function PlayerPickLayout({ data, isExpanded, toggleFlip }) {
  return (
    <>
      <div className="tile-gameInfo">
        {/* Similar approach: show HomeTeam vs. AwayTeam, if needed */}
        <img
          src={getTeamLogo(data.HomeTeam.replace(/_/g, ' '))}
          alt={`${data.HomeTeam} logo`}
          className="team-logo"
        />
        {isExpanded && <span>{getDisplayTeamName(data.HomeTeam.replace(/_/g, " "))}</span>}
        <div className="vs-text">vs</div>
        {isExpanded && <span>{getDisplayTeamName(data.AwayTeam.replace(/_/g, " "))}</span>}
        <img
          src={getTeamLogo(data.AwayTeam.replace(/_/g, ' '))}
          alt={`${data.AwayTeam} logo`}
          className="team-logo"
        />
      </div>

      <div className="tile-stat-line">
            {/* Player Name */}
            <div className="player-name">
                {data.PlayerName?.split(", ").reverse().join(" ") || "N/A"}
            </div>

            {/* Stat Type and Line */}
            <div className="stat-details">
                <span className="stat-type">
                    {data?.BetStatType?.includes("player_goal_scorer_anytime")
                        ? "Goals Anytime"
                        : data?.BetStatType?.includes("player_goals_scored_over")
                        ? "Goals Over"
                        : data?.BetStatType?.includes("player_try_scorer_anytime")
                        ? "Anytime Try Scorer"
                        : data?.BetStatType?.match(/(fantasy|disposals|tackles|goals|points)/i)?.[0] || "N/A"}
                </span>
                {(data?.BetStatType?.includes("player_goals_scored_over") ||
                    data?.BetStatType?.match(/(fantasy|disposals|tackles|goals|points)/i)?.[0]) && (
                        <span className="line-value">
                        {data.OverUnder === "Over" ? (
                            <img
                            src="/images/icons/arrows.png"
                            alt="Over Arrow"
                            className="line-icon"
                            />
                        ) : (
                            <img
                            src="/images/icons/red_arrow.png"
                            alt="Under Arrow"
                            className="line-icon"
                            />
                        )}
                        {data.StatLine || "N/A"}
                        </span>
                )}
            </div>
        </div>

      <p className="odds-line">
        <span style={{color:'#1CA733', fontWeight: 'bold'}}> 
            <img src={"/images/icon.jpg"} alt="Moolah Icon" className="moolah-icon" /> 
        </span> 
        {`${(1 / (parseFloat(data.Model_probability) / 100)).toFixed(2)}`}
        <img src={getBookieLogo(data.Bookie)} alt={data.Bookie} className="bookie-logo" />
        {data.Odds}
      </p>


      {isExpanded && (
        <div className="tile-extra">
          <div className="stats-container">
            {/* Weather Icon */}
            <div className="weather-container">

                {/* { data.Weather && data.Weather !== 'N/A' && (
                    <>
                    <img
                        src={weatherIcons[data.Weather] || '/icons/weather/default.svg'}
                        alt={data.Weather}
                        className="weather-icon"
                    />
                    <span className="weather-text">{data.Weather || 'N/A'}</span>
                    </>
                )} */}
                

                {/* Beta Testing Icon (conditionally rendered) */}
                {data.BetStatType?.includes('player_try_scorer_anytime') && (
                    <span className="beta-icon">Beta Testing</span>
                )}

                {/* Risk Analysis Button with Tooltip */}
                <div
                    className={`risk-level-button ${
                        data.Model_probability < 65
                            ? 'high-risk-card'
                            : data.Model_probability < 80
                            ? 'moderate-risk-card'
                            : 'low-risk-card'
                    }`}
                >
                    {data.Model_probability < 65
                        ? 'High Risk'
                        : data.Model_probability < 80
                        ? 'Moderate Risk'
                        : 'Low Risk'}

                    {/* Tooltip Text */}
                    <span className="tooltip-text">
                        {data.Model_probability < 65
                            ? 'high-risk. We reccomend 0.25 normal unit'
                            : data.Model_probability < 80
                            ? 'Moderate risk. We reccomend 0.5 normal unit'
                            : 'Low risk. High confidence in predictions.'}
                    </span>
                </div>
            </div>

            {/* Probabilities Section */}
            <div className="stats-table">
                <table>
                    <tbody>
                        <tr>
                            <td className="stat-label">Model Probability:</td>
                            <td className="stat-value">{data.Model_probability?.toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td className="stat-label">Implied Probability:</td>
                            <td className="stat-value">{data.Implied_probability?.toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td className="stat-label">Market Advantage:</td>
                            <td
                                className={`stat-value ${
                                    data.Model_probability - data.Implied_probability > 0 ? 'positive' : 'negative'
                                }`}
                            >
                                {(data.Model_probability - data.Implied_probability).toFixed(2)}%
                            </td>
                        </tr>
                        <tr>
                        <td className="stat-label">Expected Value (EV):</td>
                        <td className="stat-value">{(((data.Odds / (1 / (parseFloat(data.Model_probability) / 100)))-1)*100).toFixed(2)}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
      )}
    </>
  );
}

export default PlayerPickLayout;