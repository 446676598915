import React, { useEffect, useState } from 'react';
import MainResultsGraph from '../components/graphs/MainResultsGraph';
import MLProfitabilityGraph from '../components/graphs/MLProfitabilityGraph';
import PerformanceGraph from '../components/graphs/PerformanceGraph';
import ResultsTable from '../components/graphs/ResultsTable';

import Spinner from '../components/Spinner';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function ResultsLandingPage() {
    const [resultsData, setResultsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [filterType, setFilterType] = useState('week');
    const [selectedSport, setSelectedSport] = useState("AFL"); // State to track the selected sport

    // Function to handle the toggle between AFL and NRL
    const handleSportToggle = (sport) => {
        setSelectedSport(sport);
    };

    // Function to get the date for a specific range based on filterType
    const getPastDate = (daysAgo) => {
        const today = new Date();
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - daysAgo);
        return pastDate;
    };

    // Function to filter the data based on filterType
    const setFilterData = () => {
        const pastDate = getPastDate(
            filterType === 'yesterday' ? 1 :
            filterType === 'week' ? 7 :
            filterType === 'month' ? 30 :
            0
        );
        
        const pastDateIsoString = pastDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format

        if (filterType !== 'all') {
            // Filter data for selected date range
            const filtered = resultsData.filter(bet => {
                const betDate = new Date(bet.GameDate);  // Convert string to Date object
                const betDateIsoString = betDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
                return betDateIsoString >= pastDateIsoString && betDateIsoString <= new Date().toISOString().split('T')[0];
            });
            setFilteredData(filtered);
        } else {
            // Show all data (all time)
            setFilteredData(resultsData);
        }
    };
    

    useEffect(() => {
        const fetchResults = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${BACKEND_URL}/api/results/landing`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Ensure token is passed
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch results data');
                }

                const jsonResponse = await response.json();
                if (jsonResponse.success) {
                    setResultsData(jsonResponse.data); // Extract and set the `data` array
                    setFilterData(); // Filter recent or all data
                } else {
                    throw new Error('API returned success: false');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
    }, [filterType]); // Run effect when filterType changes

    useEffect(() => {
        setFilterData(); // Apply filter whenever filterType changes
    }, [filterType, resultsData]);


    if (loading) return <Spinner/>;
    if (error) return <p>Error: {error}</p>;


    return (
        <div className="resultsPageMain">
            <div className='graphTileBorder'>
                <div className='resultsHeader'>
                    <h1>ML Model Results Overview</h1>
                </div>
            </div>
            <div className='graphTileBorder'>
                <div className='resultsHeader'>
                    {/* Toggle to switch between AFL/NRL */}
                    <div className='resultsHeaderButtons'>
                        <button
                            onClick={() => handleSportToggle("NRL")}
                            style={{
                                backgroundColor: selectedSport === "NRL" ? "green" : "gray",
                            }}
                        >
                            NRL
                        </button>
                        <button
                            onClick={() => handleSportToggle("AFL")}
                            style={{
                                backgroundColor: selectedSport === "AFL" ? "green" : "gray",
                            }}
                        >
                            AFL
                        </button>
                    </div>

                    {/* Toggle between Recent / All Time */}
                    <div className='dateRange'>
                        <button
                            onClick={() => setFilterType("yesterday")}
                            style={{
                                backgroundColor: filterType === "yesterday" ? "green" : "gray",
                            }}
                        >
                            Yesterday
                        </button>
                        <button
                            onClick={() => setFilterType("week")}
                            style={{
                                backgroundColor: filterType === "week" ? "green" : "gray",
                            }}
                        >
                            Week
                        </button>
                        <button
                            onClick={() => setFilterType("month")}
                            style={{
                                backgroundColor: filterType === "month" ? "green" : "gray",
                            }}
                        >
                            Month
                        </button>
                        <button
                            onClick={() => setFilterType("all")}
                            style={{
                                backgroundColor: filterType === "all" ? "green" : "gray",
                            }}
                        >
                            All
                        </button>
                    </div>
                </div>
            </div>

            {/* Passing selectedSport as prop to all components */}
            <ResultsTable resultsData={filteredData} selectedSport={selectedSport} />
            <MainResultsGraph resultsData={filteredData} selectedSport={selectedSport} />
            <MLProfitabilityGraph resultsData={filteredData} selectedSport={selectedSport} />
            <PerformanceGraph resultsData={filteredData} selectedSport={selectedSport} />
        </div>
    );
};


export default ResultsLandingPage;