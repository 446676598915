const teamLogos = {
    "Adelaide": "/images/AFL/adelaide.png",
    "Brisbane Lions": "/images/AFL/brisbane_lions.png",
    "Carlton": "/images/AFL/carlton.png",
    "Collingwood": "/images/AFL/collingwood.png",
    "Essendon": "/images/AFL/essendon.png",
    "Fremantle": "/images/AFL/fremantle.png",
    "Greater Western Sydney": "/images/AFL/giants.png",
    "Hawthorn": "/images/AFL/hawthorn.png",
    "Melbourne": "/images/AFL/melbourne.png",
    "North Melbourne": "/images/AFL/north_melbourne.png",
    "Richmond": "/images/AFL/richmond.png",
    "St Kilda": "/images/AFL/st_kilda.png",
    "Sydney": "/images/AFL/sydney_swans.png",
    "Western Bulldogs": "/images/AFL/western_bulldogs.png",
    "Gold Coast": "/images/AFL/gold_coast.png",
    "West Coast": "/images/AFL/west_coast.png",
    "Geelong": "/images/AFL/geelong.png",
    "Port Adelaide": "/images/AFL/port_adelaide.png",

    "Sea Eagles": "/images/NRL/sea_eagles.png",
    "Rabbitohs": "/images/NRL/rabbitohs.png",
    "Roosters": "/images/NRL/roosters.png",
    "Broncos": "/images/NRL/broncos.png",
    "Knights": "/images/NRL/knights.png",
    "Raiders": "/images/NRL/raiders.png",
    "Warriors": "/images/NRL/warriors.png",
    "Sharks": "/images/NRL/sharks.png",
    "Panthers": "/images/NRL/panthers.png",
    "Storm": "/images/NRL/storm.png",
    "Eels": "/images/NRL/eels.png",
    "Bulldogs": "/images/NRL/bulldogs.png",
    "Titans": "/images/NRL/titans.png",
    "Dragons": "/images/NRL/dragons.png",
    "Dolphins": "/images/NRL/Dolphins.png",
    "Cowboys": "/images/NRL/cowboys.png",
    "Wests Tigers": "/images/NRL/west_tigers.png",
    "Sea Eagles": "/images/NRL/sea_eagles.png"


};

const betLogos = {
    "SportsBet": "/images/Bookmakers/Sportsbet icon.jpeg",
    "PointsBet (AU)": "/images/Bookmakers/Pointsbet icon.png",
    "TAB": "/images/Bookmakers/TAB icon.png",
    "BlueBet": "/images/Bookmakers/Bluebet icon.jpeg",
    "Unibet": "/images/Bookmakers/Unibet.png",
    "Ladbrokes": "/images/Bookmakers/ladbrokes icon.jpeg",
    "Bet Right": "/images/Bookmakers/betright icon.png",
    "TABtouch": "/images/Bookmakers/TABtouch.jpeg",
    "PlayUp": "/images/Bookmakers/playup.jpeg",
    "TopSport": "/images/Bookmakers/TopSport.jpeg",
    "Betfair": "/images/Bookmakers/Betfair.png",
    "Betr": "/images/Bookmakers/betr.jpeg",
    "Neds": "/images/Bookmakers/Neds.jpeg",
    "BoomBet": "/images/Bookmakers/Boombet.png",
    "Bet365 AU": "/images/Bookmakers/Bet365 AU.png"
}


const getTeamLogo = (teamName) => {
    return teamLogos[teamName]
    };

const getBookieLogo = (bookieName) => {
    return betLogos[bookieName]
}


export { getTeamLogo, getBookieLogo };