import React, { useState } from 'react';
import '../css/Accordion.css';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true); // default open or closed

  return (
    <div className="accordion-section">
      <div
        className="accordion-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3>{title}</h3>
        <span className="accordion-icon">{isOpen ? '–' : '+'}</span>
      </div>
      {isOpen && (
        <div className="accordion-content">
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;