import React, { useEffect, useState } from 'react';
import '../css/Tile.css';
import { supabase } from '../supabaseClient';
import Graph from './Graph';
import BetModal from './BetModal';

const teamLogos = {
    "Adelaide": "/images/AFL/adelaide.png",
    "Brisbane Lions": "/images/AFL/brisbane_lions.png",
    "Carlton": "/images/AFL/carlton.png",
    "Collingwood": "/images/AFL/collingwood.png",
    "Essendon": "/images/AFL/essendon.png",
    "Fremantle": "/images/AFL/fremantle.png",
    "Greater Western Sydney": "/images/AFL/giants.png",
    "Hawthorn": "/images/AFL/hawthorn.png",
    "Melbourne": "/images/AFL/melbourne.png",
    "North Melbourne": "/images/AFL/north_melbourne.png",
    "Richmond": "/images/AFL/richmond.png",
    "St Kilda": "/images/AFL/st_kilda.png",
    "Sydney": "/images/AFL/sydney_swans.png",
    "Western Bulldogs": "/images/AFL/western_bulldogs.png",
    "Gold Coast": "/images/AFL/gold_coast.png",
    "West Coast": "/images/AFL/west_coast.png",
    "Geelong": "/images/AFL/geelong.png",
    "Port Adelaide": "/images/AFL/port_adelaide.png",

    "Sea Eagles": "/images/NRL/sea_eagles.png",
    "Rabbitohs": "/images/NRL/rabbitohs.png",
    "Roosters": "/images/NRL/roosters.png",
    "Broncos": "/images/NRL/broncos.png",
    "Knights": "/images/NRL/knights.png",
    "Raiders": "/images/NRL/raiders.png",
    "Warriors": "/images/NRL/warriors.png",
    "Sharks": "/images/NRL/sharks.png",
    "Panthers": "/images/NRL/panthers.png",
    "Storm": "/images/NRL/storm.png",
    "Eels": "/images/NRL/eels.png",
    "Bulldogs": "/images/NRL/bulldogs.png",
    "Titans": "/images/NRL/titans.png",
    "Dragons": "/images/NRL/dragons.png",
    "Dolphins": "/images/NRL/Dolphins.png",
    "Cowboys": "/images/NRL/cowboys.png",
    "Wests Tigers": "/images/NRL/west_tigers.png",
    "Sea Eagles": "/images/NRL/sea_eagles.png"
};

const betLogos = {
    "SportsBet": "/images/Bookmakers/Sportsbet icon.jpeg",
    "PointsBet (AU)": "/images/Bookmakers/Pointsbet icon.png",
    "TAB": "/images/Bookmakers/TAB icon.png",
    "BlueBet": "/images/Bookmakers/Bluebet icon.jpeg",
    "Unibet": "/images/Bookmakers/Unibet.png",
    "Ladbrokes": "/images/Bookmakers/ladbrokes icon.jpeg",
    "Bet Right": "/images/Bookmakers/betright icon.png",
    "TABtouch": "/images/Bookmakers/TABtouch.jpeg",
    "PlayUp": "/images/Bookmakers/playup.jpeg",
    "TopSport": "/images/Bookmakers/TopSport.jpeg",
    "Betfair": "/images/Bookmakers/Betfair.png",
    "Betr": "/images/Bookmakers/betr.jpeg",
    "Neds": "/images/Bookmakers/Neds.jpeg",
    "BoomBet": "/images/Bookmakers/Boombet.png",
    "Bet365 AU": "/images/Bookmakers/Bet365 AU.png"
}

const weatherIcons = {
    "Clear": "/images/weather/clear-day.svg",
    "Cloudy": "/images/weather/cloudy.svg",
    "Drizzle": "images/weather/drizzle.svg",
    "Rain": "/images/weather/rain.svg"
}


const Tile = ({ data, onExpand, index, expandedIndex, selectedBets, setSelectedBets }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [showBetModal, setShowBetModal] = useState(false);


    useEffect(() => {
        setIsSelected(selectedBets.has(data.bet_id)); // Update state if bet is in `selectedBets`
    }, [selectedBets, data.bet_id]); // Runs when `selectedBets` changes
        

    const toggleExpand = () => {

        // Don't allow collapsing while the bet modal is open
        if (showBetModal) {
            return;
        }

        setIsExpanded((prev) => {
            const nextState = !prev;
    
            // Apply or remove the blur effect on the tiles-container
            const tileContainer = tileRef.current.closest('.tiles-container'); // Get the parent container
            if (tileContainer) {
                if (nextState) {
                    tileContainer.classList.add('toggle'); // Add class to blur other tiles
                } else {
                    tileContainer.classList.remove('toggle');
                }
            }
    
            return nextState;
        });
    };
    

    const toggleFlip = (event) => {
        if (event) {
            event.stopPropagation(); // Prevent triggering expand
        }
        setIsFlipped((prev) => !prev); // Toggle flip state
    };

    // Ref to the tile element to calculate its position on screen
    const tileRef = React.useRef();


    // 1) If bet is NOT selected -> open modal to add
    // 2) If bet IS selected -> remove bet
    const handleButtonClick = async (event) => {
        event.stopPropagation();
    
        if (!isSelected) {
        // Expand tile if you want it expanded before adding
        if (!isExpanded) {
            setIsExpanded(true);
        }
        // Show the bet modal
        setShowBetModal(true);
        } else {
        // Remove the bet
        await removeBetFromAccount();
        setIsSelected(false);
        setSelectedBets((prev) => {
            const updatedBets = new Set(prev);
            updatedBets.delete(data.bet_id);
            return updatedBets;
        });
        }
    };

    //TODO: update endpoint
    const removeBetFromAccount = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                alert('User is not logged in. Please log in first.');
                return;
            }
    
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user-bets/remove`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ bet_id: data.bet_id }),
            });
    
            if (!response.ok) {
                const result = await response.json();
                console.error('Error removing bet:', result.message);
                alert('Failed to remove bet: ' + result.message);
            } else {
                console.log('Bet removed successfully');
            }
        } catch (err) {
            console.error('Error removing bet:', err.message);
            alert('An error occurred while removing the bet.');
        }
    };


    // Called by the modal's Confirm button
    const handleConfirmBet = async (betAmount) => {
        // 1. Actually add the bet
        await addBetToAccount(betAmount);

        // 2. Update local states
        setIsSelected(true);
        setSelectedBets((prev) => new Set(prev).add(data.bet_id));

        // 3. Close the modal
        setShowBetModal(false);
    };

    // Called by the modal's Cancel button
    const handleCancelBet = () => {
        setShowBetModal(false);
    };


    // Automatically unflip when tile is unexpanded
    useEffect(() => {
        if (!isExpanded) {
            setIsFlipped(false);
        }
    }, [isExpanded]);

    const getDisplayTeamName = (teamName) => {
        return teamName === "Greater Western Sydney" ? "GWS" : teamName;
    };
    

    const addBetToAccount = async (betAmount) => {
        try {
            const token = localStorage.getItem('token'); // Ensure the token is stored in localStorage
            if (!token) {
                alert('User is not logged in. Please log in first.');
                return;
            }
    
            // Fetch the logged-in user's ID from Supabase
            const { data: { user } } = await supabase.auth.getUser();
            const userId = user.id; // Explicitly extract the userId
            console.log("Fetched user ID:", userId); // Log userId directly
    
            // Ensure PlayerOddsID is available in the data object
            console.log('Tile Data:', data);
            console.log('PlayerOddsID:', data.bet_id);
    
            if (!data.bet_id) {
                console.error('Error: PlayerOddsID is missing in data.');
                alert('Failed to add bet: Missing bet information.');
                return;
            }
    
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user-bets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Pass the user's token
                },
                body: JSON.stringify({
                    userId, // Pass the userId to the backend
                    betData: {
                        bet_id: data.bet_id,
                        stake: betAmount,
                    },
                }),
            });
    
            const result = await response.json();
    
            if (!response.ok) {
                console.error('Error adding bet:', result.message);
                alert('Failed to add bet: ' + result.message);
            } else {
                console.log('Bet added successfully:', result);
            }
        } catch (err) {
            console.error('Error adding bet:', err.message);
            alert('An error occurred while adding the bet.');
        }
    };



    // helper function to fetch team logos to add to cards
    const getTeamLogo = (teamName) => {
        return teamLogos[teamName]
    };

    const getBookieLogo = (bookieName) => {
        return betLogos[bookieName]
    }

    const getWeatherIcon = (weather) => {
        return weatherIcons[weather]
    }

    return (
        <div
            ref={tileRef}
            className={`tile ${isExpanded ? 'expanded' : ''} ${isFlipped ? 'flipped' : ''}`}
            onClick={toggleExpand} // Use local toggleExpand function
        >
            {!isFlipped ? (
                // Front ****************
                <>
                
                    <div className="tile-header">
                        <div className="tile-header-left">
                            <img
                                src={"/images/AFL/afl.png"} // Replace this with dynamic logic for other sports
                                alt="AFL Logo"
                                className="sport-icon"
                            />
                        </div>

                        <div className="tile-header-middle">
                            <div className="date-container">
                                <span className="game-date">
                                    {data.GameDate
                                        ? new Date(data.GameDate).toLocaleDateString('en-GB', {
                                            weekday: 'short',
                                            day: '2-digit',
                                            month: 'short',
                                        })
                                        : 'N/A'}
                                </span>
                            </div>
                        </div>


                        <div className="tile-header-right">
                            <button
                                onClick={handleButtonClick}
                                className={`tile-button ${isSelected ? "selected" : ""}`}
                            >
                                {isSelected ? "\u00D7" : "+"}
                            </button>

                            {/* Conditionally render the modal if needed */}
                            {showBetModal && (
                                <BetModal
                                onConfirm={handleConfirmBet}
                                onCancel={handleCancelBet}
                                />
                            )}
                        </div>
                    </div>

                    <div className="tile-gameInfo">
                        {/* Add logos */}
                        <img
                            src={getTeamLogo(data.HomeTeam.replace(/_/g, " "))}
                            alt={`${data.HomeTeam} logo`}
                            className="team-logo"
                        />
                        {isExpanded && (
                            <span className="team-names home-team-names">
                                {getDisplayTeamName(data.HomeTeam.replace(/_/g, " "))}
                            </span>
                        )}
                        <div className='vs-text'>
                            {" vs "}
                        </div>
                        {isExpanded && (
                            <span className="team-names away-team-names">
                                {getDisplayTeamName(data.AwayTeam.replace(/_/g, " "))}
                            </span>
                        )}
                        <img
                            src={getTeamLogo(data.AwayTeam.replace(/_/g, " "))}
                            alt={`${data.AwayTeam} logo`}
                            className="team-logo"
                        />
                    </div>

                    <div className="tile-stat-line">
                        {/* Player Name */}
                        <div className="player-name">
                            {data.PlayerName?.split(", ").reverse().join(" ") || "N/A"}
                        </div>

                        {/* Stat Type and Line */}
                        <div className="stat-details">
                            <span className="stat-type">
                                {data?.BetStatType?.includes("player_goal_scorer_anytime")
                                    ? "Goals Anytime"
                                    : data?.BetStatType?.includes("player_goals_scored_over")
                                    ? "Goals Over"
                                    : data?.BetStatType?.includes("player_try_scorer_anytime")
                                    ? "Anytime Try Scorer"
                                    : data?.BetStatType?.match(/(fantasy|disposals|tackles|goals|points)/i)?.[0] || "N/A"}
                            </span>
                            {(data?.BetStatType?.includes("player_goals_scored_over") ||
                                data?.BetStatType?.match(/(fantasy|disposals|tackles|goals|points)/i)?.[0]) && (
                                    <span className="line-value">
                                    {data.OverUnder === "Over" ? (
                                      <img
                                        src="/images/icons/arrows.png"
                                        alt="Over Arrow"
                                        className="line-icon"
                                      />
                                    ) : (
                                      <img
                                        src="/images/icons/red_arrow.png"
                                        alt="Under Arrow"
                                        className="line-icon"
                                      />
                                    )}
                                    {data.StatLine || "N/A"}
                                  </span>
                            )}
                        </div>
                    </div>

                        {/* Our predicted odds and the market odds are displayed in this section */}
                        <p className="odds-line">
                            <span style={{color:'#1CA733', fontWeight: 'bold'}}> 
                                <img src={"/images/icon.jpg"} alt="Moolah Icon" className="moolah-icon" /> 
                            </span> 
                            {`${(1 / (parseFloat(data.Model_probability) / 100)).toFixed(2)}`}
                        
                            <img src={getBookieLogo(data.Bookie)} alt={`${data.Bookie}`} className="bookie-logo" /> 
                            {data.Odds}
                        </p>


                        {/* Extra content for expanded tile */}
                        {isExpanded && (
                            <div className="tile-extra">
                                <div className="stats-container">
                                    {/* Weather Icon */}
                                    <div className="weather-container">

                                        {/* { data.Weather && data.Weather !== 'N/A' && (
                                            <>
                                            <img
                                                src={weatherIcons[data.Weather] || '/icons/weather/default.svg'}
                                                alt={data.Weather}
                                                className="weather-icon"
                                            />
                                            <span className="weather-text">{data.Weather || 'N/A'}</span>
                                            </>
                                        )} */}
                                        

                                        {/* Beta Testing Icon (conditionally rendered) */}
                                        {data.BetStatType?.includes('player_try_scorer_anytime') && (
                                            <span className="beta-icon">Beta Testing</span>
                                        )}

                                        {/* Risk Analysis Button with Tooltip */}
                                        <div
                                            className={`risk-level-button ${
                                                data.Model_probability < 65
                                                    ? 'high-risk-card'
                                                    : data.Model_probability < 80
                                                    ? 'moderate-risk-card'
                                                    : 'low-risk-card'
                                            }`}
                                        >
                                            {data.Model_probability < 65
                                                ? 'High Risk'
                                                : data.Model_probability < 80
                                                ? 'Moderate Risk'
                                                : 'Low Risk'}

                                            {/* Tooltip Text */}
                                            <span className="tooltip-text">
                                                {data.Model_probability < 65
                                                    ? 'high-risk. We reccomend 0.25 normal unit'
                                                    : data.Model_probability < 80
                                                    ? 'Moderate risk. We reccomend 0.5 normal unit'
                                                    : 'Low risk. High confidence in predictions.'}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Probabilities Section */}
                                    <div className="stats-table">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="stat-label">Model Probability:</td>
                                                    <td className="stat-value">{data.Model_probability?.toFixed(2)}%</td>
                                                </tr>
                                                <tr>
                                                    <td className="stat-label">Implied Probability:</td>
                                                    <td className="stat-value">{data.Implied_probability?.toFixed(2)}%</td>
                                                </tr>
                                                <tr>
                                                    <td className="stat-label">Market Advantage:</td>
                                                    <td
                                                        className={`stat-value ${
                                                            data.Model_probability - data.Implied_probability > 0 ? 'positive' : 'negative'
                                                        }`}
                                                    >
                                                        {(data.Model_probability - data.Implied_probability).toFixed(2)}%
                                                    </td>
                                                </tr>
                                                <tr>
                                                <td className="stat-label">Expected Value (EV):</td>
                                                <td className="stat-value">{(((data.Odds / (1 / (parseFloat(data.Model_probability) / 100)))-1)*100).toFixed(2)}%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Add right edge button */}
                        {isExpanded && (
                            <div
                                className="tile-right-edge-button"
                                onClick={(event) => {
                                    if (event) event.stopPropagation(); // Prevent click event from propagating
                                    toggleFlip(event);
                                }}
                            >
                                <span className="flip-icon">{'>'}</span>
                            </div>
                        )}
                        
                </>
            ) : (
                /// Back ******************************************************************************************************
                <div className="tile-back">
                    {isExpanded && (
                        <div
                            className="tile-right-edge-button"
                            onClick={(event) => {
                                if (event) event.stopPropagation();
                                toggleFlip(event);
                            }}
                        >
                            <span className="flip-icon">{'<'}</span>
                        </div>
                    )}
                    {/* <h3>Player Performance (Last 5 Games)</h3> */}
                    <Graph
                        playerId={data.PlayerID}
                        gameDate={data.GameDate}
                        betStatType={data.BetStatType}
                        benchmarkValue={data.StatLine}
                    />
                </div>

            )}
        </div>
    );
};

export default Tile;
