import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../css/GenerateModal.css'; // your CSS file

function GenerateModal({ isOpen, onClose, onGenerateMulti }) {
  // Local state for form fields
  const [sport, setSport] = useState('ALL');
  const [sportLabel, setSportLabel] = useState('ALL');
  const [isSportOpen, setIsSportOpen] = useState(false);

  const [sportsbook, setSportsbook] = useState('ALL');
  const [sportsbookLabel, setSportsbookLabel] = useState('ALL');
  const [isSportsbookOpen, setIsSportsbookOpen] = useState(false);

  const [type, setType] = useState('High Risk');
  const [typeLabel, setTypeLabel] = useState('High Risk');
  const [isTypeOpen, setIsTypeOpen] = useState(false);

  const [legs, setLegs] = useState(2);

  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState('ALL');
  const [selectedGameLabel, setSelectedGameLabel] = useState('ALL');
  const [isGameDropdownOpen, setIsGameDropdownOpen] = useState(false);

  const [markets, setMarkets] = useState('ALL');
  const [marketsLabel, setMarketsLabel] = useState('ALL');
  const [isMarketsOpen, setIsMarketsOpen] = useState(false);

  const [excludeMarkets, setExcludeMarkets] = useState('None');
  const [excludeMarketsLabel, setExcludeMarketsLabel] = useState('None');
  const [isExcludeOpen, setIsExcludeOpen] = useState(false);

  // Arrays of items for each dropdown
  const sportItems = [
    { value: 'ALL', label: 'ALL' },
    { value: 'AFL', label: 'AFL' },
    { value: 'NRL', label: 'NRL' },
  ];

  const sportsbookItems = [
    { value: 'ALL', label: 'ALL' },
    // { value: 'SportsBet', label: 'SportsBet' },
  ];

  const typeItems = [
    { value: 'High Risk', label: 'High Risk' },
    { value: 'Balanced', label: 'Balanced' },
    { value: 'Low Risk', label: 'Low Risk' },
  ];

  const marketsItems = [
    { value: 'ALL', label: 'ALL' },
    { value: 'player_disposals_over', label: 'player disposals over' },
    { value: 'player_goals_scored_over', label: 'player goals scored over' },
    { value: 'player_goal_scorer_anytime', label: 'player goal scorer anytime' },
    { value: 'player_try_scorer_anytime', label: 'player try scorer anytime' },
    { value: 'h2h', label: 'Head to Head' },
  ];

  const excludeItems = [
    { value: 'None', label: 'None' },
    { value: 'player_disposals_over', label: 'player disposals over' },
    { value: 'player_goals_scored_over', label: 'player goals scored over' },
    { value: 'player_goal_scorer_anytime', label: 'player goal scorer anytime' },
    { value: 'player_try_scorer_anytime', label: 'player try scorer anytime' },
    { value: 'h2h', label: 'Head to Head' },
    { value: 'spreads', label: 'Spreads' },
  ];

  // Function to fetch games based on the sport
  useEffect(() => {
    if (!isOpen) return;

    const token = localStorage.getItem('token');
    async function fetchGames() {
      try {
        let fetchedGames = [];
        if (sport === 'ALL') {
          // Fetch AFL
          const resAFL = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/games/relevant-games?sport=AFL`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const gamesAFL = await resAFL.json();

          // Fetch NRL
          const resNRL = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/games/relevant-games?sport=NRL`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const gamesNRL = await resNRL.json();

          fetchedGames = [...(gamesAFL || []), ...(gamesNRL || [])];
          fetchedGames.sort((a, b) => new Date(a.GameDate) - new Date(b.GameDate));
        } else {
          // Only fetch for the selected sport
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/games/relevant-games?sport=${sport}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          fetchedGames = await res.json();
        }
        setGames(fetchedGames || []);
      } catch (error) {
        console.error('Error fetching games:', error);
        setGames([]);
      }
    }

    fetchGames();
  }, [sport, isOpen]);

  if (!isOpen) return null; // Don’t render if closed

  // Convert the "games" array into items if needed
  const gameItems = [
    { value: 'ALL', label: 'ALL' },
    ...games.map((g) => ({
      value: g.GameID,
      label: `${g.HomeTeamID.replace(/_/g, ' ')} vs ${g.AwayTeamID.replace(/_/g, ' ')}`,
    })),
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass form data up
    onGenerateMulti({
      sport,
      sportsbook,
      type,
      legs: parseInt(legs, 10),
      selectedGame,
      markets,
      excludeMarkets,
    });
    onClose();
  };

  // Helper to handle a generic item selection
  const handleItemSelect = (value, label, setter, labelSetter, toggleSetter) => {
    setter(value);
    labelSetter(label);
    toggleSetter(false);
  };

  // Render a custom dropdown
  const renderDropdown = (label, selectedVal, selectedLbl, isOpen, setIsOpen, items, setter, labelSetter) => (
    <div className="form-group">
      <label>{label}</label>
      <div className="gdropdown-input" onClick={() => setIsOpen(!isOpen)}>
        <div className="gdropdown-selected">
          {selectedLbl || 'Select...'}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            width="16"
            height="16"
            style={{ marginLeft: "8px" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>

      {isOpen && (
        <div className="gdropdown-menu">
          {items.map((item) => (
            <div
              key={item.value}
              className="gdropdown-item"
              onClick={() => handleItemSelect(
                item.value,
                item.label,
                setter,
                labelSetter,
                setIsOpen
              )}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>BUILD YOUR MULTI</h2>

        <form onSubmit={handleSubmit} className="slip-form">
          {/* Sport */}
          {renderDropdown(
            "Sport",
            sport,
            sportLabel,
            isSportOpen,
            setIsSportOpen,
            sportItems,
            setSport,
            setSportLabel
          )}

          {/* Sportsbook */}
          {renderDropdown(
            "Sportsbook",
            sportsbook,
            sportsbookLabel,
            isSportsbookOpen,
            setIsSportsbookOpen,
            sportsbookItems,
            setSportsbook,
            setSportsbookLabel
          )}

          {/* Type */}
          {renderDropdown(
            "Type",
            type,
            typeLabel,
            isTypeOpen,
            setIsTypeOpen,
            typeItems,
            setType,
            setTypeLabel
          )}

          {/* Legs input */}
          <div className="form-group">
            <label>Legs</label>
            <input
              type="number"
              min="1"
              value={legs}
              onChange={(e) => setLegs(e.target.value)}
            />
          </div>

          {/* Games */}
          <div className="form-group">
            <label>Games</label>
            <div className="gdropdown-input" onClick={() => setIsGameDropdownOpen(!isGameDropdownOpen)}>
              <div className="gdropdown-selected">
                {selectedGameLabel || 'Select Game'}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  width="16"
                  height="16"
                  style={{ marginLeft: "8px" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
            {isGameDropdownOpen && (
              <div className="gdropdown-menu">
                {gameItems.map((item) => (
                  <div
                    key={item.value}
                    className="gdropdown-item"
                    onClick={() => handleItemSelect(
                      item.value,
                      item.label,
                      setSelectedGame,
                      setSelectedGameLabel,
                      setIsGameDropdownOpen
                    )}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Markets */}
          {renderDropdown(
            "Markets",
            markets,
            marketsLabel,
            isMarketsOpen,
            setIsMarketsOpen,
            marketsItems,
            setMarkets,
            setMarketsLabel
          )}

          {/* Exclude Markets */}
          {renderDropdown(
            "Exclude Markets",
            excludeMarkets,
            excludeMarketsLabel,
            isExcludeOpen,
            setIsExcludeOpen,
            excludeItems,
            setExcludeMarkets,
            setExcludeMarketsLabel
          )}

          {/* Buttons */}
          <div className="form-buttons">
            <button
              id="close-modal-button"
              type="button"
              className="close-multi-button"
              onClick={onClose}
            >
              Close
            </button>
            <button
              id="generate-multi-button"
              type="submit"
              className="generate-multi-button"
            >
              Generate
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
}

export default GenerateModal;