import React from "react";
import "../css/ConfirmDeleteModal.css"; // Add styles

const ConfirmDeleteModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="confirm-modal-overlay">
      <div className="confirm-modal">
        <h3>Are you sure?</h3>
        <p>This action cannot be undone.</p>
        <div className="modal-buttons">
          <button className="confirm-button" onClick={onConfirm}>Delete</button>
          <button className="cancel-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;