import React from 'react';
import '../css/Footer.css';


function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
      <p className="footer-gambling-help">
        If gambling ever feels like it’s becoming an issue, we encourage you to visit{' '}
        <a href="https://www.gamblinghelponline.org.au/" target="_blank" rel="noopener noreferrer" className="footer-link">
          Gambling Help Online{' '}
        </a> 
        for assistance and resources.
      </p>
        <div className="footer-lower">
          <p>&copy; All rights reserved Mooolah 2024</p>

          {/* Add a support email here */}
          <p className="footer-support-email">
            Have questions? Email us at{' '}
            <a href="mailto:admin@mooolah.com" className="footer-link">
              admin@mooolah.com
            </a>
          </p>

          <div className="footer-links">
            <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
            <span className="footer-separator"> | </span>
            <a href="/terms-of-service" className="footer-link">Terms of Service</a>
            
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;