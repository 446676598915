import React, { useState, useEffect, useRef } from 'react';
import '../css/FilterMenu.css';

function FilterMenu({
    games,
    selectedGame,
    setSelectedGame,
    selectedDate,
    setSelectedDate,
    selectedSport,
    setSelectedSport,
    onSubmitFilters, // Callback for submitting filters
}) {
    const [isSportDropdownOpen, setIsSportDropdownOpen] = useState(false);
    const [isGameDropdownOpen, setIsGameDropdownOpen] = useState(false);
    const [minAdvantage, setMinAdvantage] = useState('');
    const [maxAdvantage, setMaxAdvantage] = useState('');
    const [minOdds, setMinOdds] = useState('');
    const [maxOdds, setMaxOdds] = useState('');

    const [isSubmenuVisible, setIsSubmenuVisible] = useState(true);
    const lastScrollY = useRef(window.scrollY);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the page from refreshing
        console.log('Submitting filters:', {
            sport: selectedSport,
            game: selectedGame,
            date: selectedDate,
            minAdvantage: parseFloat(minAdvantage) || null,
            maxAdvantage: parseFloat(maxAdvantage) || null,
            minOdds: parseFloat(minOdds) || null,
            maxOdds: parseFloat(maxOdds) || null,
        });
        if (onSubmitFilters) {
            onSubmitFilters({
                sport: selectedSport,
                game: selectedGame,
                date: selectedDate,
                minAdvantage: parseFloat(minAdvantage) || null,
                maxAdvantage: parseFloat(maxAdvantage) || null,
                minOdds: parseFloat(minOdds) || null,
                maxOdds: parseFloat(maxOdds) || null,
            });
        }
    };

    const toggleDropdown = (dropdown) => {
        if (dropdown === 'sport') {
            setIsSportDropdownOpen(!isSportDropdownOpen);
            setIsGameDropdownOpen(false); // close other dropdowns
        } else if (dropdown === 'game') {
            setIsGameDropdownOpen(!isGameDropdownOpen);
            setIsSportDropdownOpen(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
    
          if (currentScrollY <= 10) {
            setIsSubmenuVisible(true);
          } else if (currentScrollY > lastScrollY.current) {
            setIsSubmenuVisible(false);
          } else {
            setIsSubmenuVisible(true);
          }
    
          lastScrollY.current = currentScrollY;
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    

    return (
        <div className={`filter-menu ${isSubmenuVisible ? '' : 'hidden'}`}>
            <div className="filter-options">
                {/* First Row: Sport, Game, Date */}
                <div className="filter-row">

                    {/* GAME DROPDOWN */}
                    <div className="filter-item">
                        <label>Game:</label>
                        <div 
                            className="fmdropdown-input"
                            onClick={() => toggleDropdown('game')}
                        >
                            <div className="fmdropdown-selected">
                                {selectedGame.replace(/_/g, " ") || "Select Game"}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="size-12"
                                    width="16"
                                    height="16"
                                    margin="12"
                                    style={{ transform: "translate(5px, 2px)" }}
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                        className="dropdown-icon"
                                    />
                                </svg>
                            </div>
                            
                            {isGameDropdownOpen && (
                                <div className="fmdropdown-menu">
                                    {games.map((game) => (
                                        <div 
                                            key={game.GameID}
                                            className="fmdropdown-item"
                                            onClick={() => {
                                                setSelectedGame(`${game.HomeTeamID} vs ${game.AwayTeamID}`);
                                                setIsGameDropdownOpen(false);
                                            }}
                                        >
                                            {`${game.HomeTeamID.replace(/_/g, " ")} vs ${game.AwayTeamID.replace(/_/g, " ")}`}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* DATE INPUT */}
                    <div className="filter-item">
                        <label htmlFor="fmdate-filter">Date:</label>
                        <input
                            id="date-filter"
                            type="date"
                            value={selectedDate || ''}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        />
                    </div>
                </div>

                {/* Second Row: Mooolah Advantage, Odds */}
                <div className="filter-row">
                    <div className="filter-item">
                        <label>Mooolah Advantage:</label>
                        <div className="fmfilter-range">
                            <input
                                type="number"
                                placeholder="Min"
                                value={minAdvantage}
                                onChange={(e) => setMinAdvantage(e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Max"
                                value={maxAdvantage}
                                onChange={(e) => setMaxAdvantage(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="filter-item">
                        <label>Odds:</label>
                        <div className="fmfilter-range">
                            <input
                                type="number"
                                placeholder="Min"
                                value={minOdds}
                                onChange={(e) => setMinOdds(e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Max"
                                value={maxOdds}
                                onChange={(e) => setMaxOdds(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="filter-submit">
                    <button className="submit-button" onClick={handleSubmit}>
                        Apply Filters
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FilterMenu;
