import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

function ProfileForm({ userId, currentUsername, currentEmail, currentPhone }) {
  const [username, setUsername] = useState(currentUsername);
  const [email, setEmail] = useState(currentEmail);
  const [phone, setPhone] = useState(currentPhone);
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleUpdateField = async (field, value) => {
    try {
      if (field === 'email') {
        // Update email in the auth user profile
        const { error } = await supabase.auth.updateUser({ email: value });
        if (error) throw error;
        alert('Email updated successfully');
        setIsEditingEmail(false); // Exit edit mode for email
      } else {
        // Update other fields in the 'profiles' table
        const updates = { [field]: value };
        const { error } = await supabase
          .from('profiles')
          .update(updates)
          .eq('user_id', userId);
        if (error) throw error;
        alert(`${field} updated successfully`);
      }
    } catch (error) {
      alert(`Failed to update ${field}: ${error.message}`);
    } finally {
      // Turn off edit mode for the field
      if (field === 'username') setIsEditingUsername(false);
      if (field === 'phone') setIsEditingPhone(false);
    }
  };

  const handleChangePassword = async () => {
    const { error: signInError } = await supabase.auth.signInWithPassword({
      email,
      password: currentPassword,
    });

    if (signInError) {
      alert('Current password is incorrect');
      return;
    }

    const { error: updateError } = await supabase.auth.updateUser({ password: newPassword });

    if (updateError) {
      alert(`Failed to update password: ${updateError.message}`);
    } else {
      alert('Password updated successfully');
      setCurrentPassword('');
      setNewPassword('');
    }
  };

  return (
    <div className="form">
      <h3>Update Profile</h3>

      {/* Username Section */}
      <div className="profile-field">
        <label>Username</label>
        {isEditingUsername ? (
          <>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter new username"
            />
            <button onClick={() => handleUpdateField('username', username)}>Save</button>
            <button onClick={() => setIsEditingUsername(false)}>Cancel</button>
          </>
        ) : (
          <>
            <p>{username}</p>
            <button onClick={() => setIsEditingUsername(true)}>Edit Username</button>
          </>
        )}
      </div>

      {/* Email Section */}
      <div className="profile-field">
        <label>Email</label>
        {isEditingEmail ? (
          <>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter new email"
            />
            <button onClick={() => handleUpdateField('email', email)}>Save</button>
            <button onClick={() => setIsEditingEmail(false)}>Cancel</button>
          </>
        ) : (
          <>
            <p>{email}</p>
            <button onClick={() => setIsEditingEmail(true)}>Edit Email</button>
          </>
        )}
      </div>

      {/* Phone Section */}
      <div className="profile-field">
        <label>Phone</label>
        {isEditingPhone ? (
          <>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter new phone number"
            />
            <button onClick={() => handleUpdateField('phone', phone)}>Save</button>
            <button onClick={() => setIsEditingPhone(false)}>Cancel</button>
          </>
        ) : (
          <>
            <p>{phone}</p>
            <button onClick={() => setIsEditingPhone(true)}>Edit Phone</button>
          </>
        )}
      </div>

      {/* Password Section */}
      <div className="profile-field">
        <label>Current Password</label>
        <input
          type="password"
          placeholder="Enter current password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </div>

      <div className="profile-field">
        <label>New Password</label>
        <input
          type="password"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>

      <button onClick={handleChangePassword}>Change Password</button>
    </div>
  );
}

export default ProfileForm;