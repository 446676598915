import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import { supabase } from './supabaseClient';

import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import PlansPage from './pages/PlansPage';
import CancelPage from './pages/CancelPage';
import Settings from './pages/Settings';
import Home from './pages/HomePage';
import ResultsLandingPage from './pages/ResultsLandingPage';
import UserResultsHistory from './pages/UserResultsHistory';
import PrivacyPage from './pages/PrivacyPage';
import TOS from './pages/TOS';
import PerformancePage from './pages/PerformancePage';
import MultiCreatorPage from './pages/MultiCreatorPage'
import ResetPassword from './pages/ResetPasswordPage';
import { AuthProvider } from './context/AuthContext';
import './css/Global.css';
import { trackVisit } from './utils/tracking';

function App() {
  // Track cookie visit data on mount/unmount
  useEffect(() => {
    trackVisit({
      entryPage: window.location.href,
      referrer: document.referrer,
    });
    return () => {
      trackVisit({
        exitPage: window.location.href,
      });
    };
  }, []);

  // Set up Supabase auth listener to update token on auth state change
    // Track cookie visit data on mount/unmount
    useEffect(() => {
      trackVisit({
        entryPage: window.location.href,
        referrer: document.referrer,
      });
      return () => {
        trackVisit({
          exitPage: window.location.href,
        });
      };
    }, []);

  // Set up Supabase auth listener to update token on auth state change
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
        if (session) {
            console.log("Auth state changed, updating token:", session.access_token);
            localStorage.setItem("token", session.access_token);
        }
      if (session) {
        console.log("Auth state changed, updating token:", session.access_token);
        localStorage.setItem("token", session.access_token);
      }
    });

      return () => {
          authListener?.unsubscribe();
      };
    return () => {
      authListener?.unsubscribe();
    };
  }, []);

  return (
    <div className="App">
      <AuthProvider>  {/* Wrap the app in AuthProvider */}
          <Navbar />
          {/* This wrapper is our main content area that will grow/shrink */}
          <div className="content-wrap">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/plans" element={<PlansPage />} />
              <Route path="/cancel" element={<CancelPage />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/home" element={<Home />} />
              <Route path="/results" element={<ResultsLandingPage />} />
              <Route path="/user-results" element={<UserResultsHistory />} />
              <Route path="/multi-creator" element={<MultiCreatorPage />} />
              <Route path="/privacy-policy" element={<PrivacyPage />} />
              <Route path="/terms-of-service" element={<TOS />} />
              <Route path="/performance" element={<PerformancePage />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
          </div>
          <Footer />
      </AuthProvider>
    </div>
  );
}

export default App;
