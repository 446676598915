import React, { useEffect, useState } from 'react';
import {
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import '../../css/gameGraphs.css';

const GameGraph = ({ dataSets }) => {
    const [selectedMetric, setSelectedMetric] = useState('DefensivePressureIndex'); // Default metric
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // List of metrics from games array
    const metrics = [
        'DefensivePressureIndex',
        'DisposalEfficiency',
        'EfficiencyInside50',
        'MatchScore',
        'ScoringEfficency',
        'TeamStrengthPerformance',
    ];

    const teamColors = {
        Sydney: '#D71920',
        Essendon: '#CC2031',
        Adelaide: '#AD7E00',
        Collingwood: '#3357FF',
        Western_Bulldogs: '#014896',
        Brisbane_Lions: '#75163D',
        Hawthorn: '#7F3709',
        Melbourne: '#323AB7',
        North_Melbourne: '#004AE5',
        Port_Adelaide: '#01B7E5',
        Greater_Western_Sydney: '#F15C22',
        St_Kilda: '#ED1C24',
        Gold_Coast: '#D71920',
        West_Coast: '#062EE2',
        Freemantle: '#2A2D81',
        Richmond: '#FBC33F',
        Geelong: '#2A70D8',
        Carlton: '#153F73',
    };

    const teamLogos = {
        "Adelaide": "/images/AFL/adelaide.png",
        "Brisbane Lions": "/images/AFL/brisbane_lions.png",
        "Carlton": "/images/AFL/carlton.png",
        "Collingwood": "/images/AFL/collingwood.png",
        "Essendon": "/images/AFL/essendon.png",
        "Fremantle": "/images/AFL/fremantle.png",
        "Greater Western Sydney": "/images/AFL/giants.png",
        "Hawthorn": "/images/AFL/hawthorn.png",
        "Melbourne": "/images/AFL/melbourne.png",
        "North Melbourne": "/images/AFL/north_melbourne.png",
        "Richmond": "/images/AFL/richmond.png",
        "St Kilda": "/images/AFL/st_kilda.png",
        "Sydney": "/images/AFL/sydney_swans.png",
        "Western Bulldogs": "/images/AFL/western_bulldogs.png",
        "Gold Coast": "/images/AFL/gold_coast.png",
        "West Coast": "/images/AFL/west_coast.png",
        "Geelong": "/images/AFL/geelong.png",
        "Port Adelaide": "/images/AFL/port_adelaide.png"
    };

    const gamesData = dataSets?.games || []; // Safely access games array

    // Process games data to separate metrics by TeamID
    const processedData = (() => {
        const teamData = {};
        gamesData.forEach((game) => {
            const { TeamID, GameDate } = game;
            if (!teamData[TeamID]) {
                teamData[TeamID] = [];
            }
            teamData[TeamID].push({
                index: teamData[TeamID].length, // Use index for X-axis
                value: game[selectedMetric] || 0, // Use selected metric
            });
        });

        // Combine team data into a single dataset
        const maxLength = Math.max(...Object.values(teamData).map((team) => team.length));
        const combinedData = Array.from({ length: maxLength }).map((_, index) => {
            const entry = { index }; // Use index as X-axis
            Object.keys(teamData).forEach((teamID) => {
                entry[teamID] = teamData[teamID][index]?.value || null; // Add team metric or null
            });
            return entry;
        });

        return combinedData;
    })();

    const renderCustomLegend = (props) => {
        const { payload } = props; // Legend items
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px',
                    flexWrap: 'wrap',
                }}
            >
                {payload.map((entry) => (
                    <div
                        key={entry.value}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0px',
                            border: `2px solid ${entry.color}`,
                            borderRadius: '8px',
                            padding: '0px 10px 0px 0px',
                            backgroundColor: '#2b2b2b',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={teamLogos[entry.value]}
                            alt={entry.value}
                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                        />
                        <span
                            style={{
                                color: '#fff',
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {entry.value.replace(/_/g, ' ')}
                        </span>
                    </div>
                ))}
            </div>
        );
    };
    

    // Debugging for data transformation
    useEffect(() => {
        console.log('Selected Metric:', selectedMetric);
        console.log('Processed Data:', processedData);
    }, [selectedMetric, gamesData]);

    return (
        <div className="container-graph">
            {/* Buttons to switch metrics */}
            <div className="game-graph">
                {/* Custom Dropdown */}
                <div
                    className="dropdown-input"
                    onClick={(event) => {
                        event.stopPropagation(); // Stop click from flipping the tile
                        setIsDropdownOpen(!isDropdownOpen);
                    }}
                >
                    <div className="dropdown-selected">
                        {selectedMetric.replace(/([A-Z])/g, ' $1').trim()}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-12"
                            width="16"
                            height="16"
                            margin="12"
                            style={{ transform: "translate(5px, 2px)" }}
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                className="dropdown-icon"
                            />
                        </svg>
                    </div>

                    {/* Dropdown Menu */}
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            {metrics.map((metric) => (
                                <div
                                    key={metric}
                                    className="dropdown-item"
                                    onClick={() => {
                                        setSelectedMetric(metric);
                                        setIsDropdownOpen(false);
                                    }}
                                >
                                    {metric.replace(/([A-Z])/g, ' $1').trim()}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
    
            {/* Line Chart for the selected metric */}
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={processedData}>
                    <XAxis
                        dataKey="index"
                        tickFormatter={(tick) => `${tick + 1}`} // Show index starting from 1
                        label={{ value: '', position: 'insideBottom', offset: -10 }}
                    />
                    <YAxis />
                    <Tooltip
                        formatter={(value, name) => [`${value}`, `${name}`]}
                        labelFormatter={(label) => `Game ${label + 1}`}
                    />
                    <Legend content={renderCustomLegend} />
                    {Object.keys(gamesData.reduce((keys, game) => ({ ...keys, [game.TeamID]: true }), {})).map(
                        (teamID, index) => (
                            <Line
                                type="monotone"
                                dataKey={teamID}
                                stroke={teamColors[teamID]}
                                name={teamID.replace(/_/g, ' ')}
                                activeDot={{ r: 8 }}
                            />
                        )
                    )}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default GameGraph;