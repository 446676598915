import React from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { format, parseISO } from 'date-fns';
import '../css/Performance/ProfitLossGraph.css';

const ProfitLossGraph = ({ data, userBets, filterType }) => {
  // Map userBets to easily find stake by bet_id
  const userBetsMap = userBets.reduce((acc, ub) => {
    acc[ub.bet_id] = ub.unit_placed;
    return acc;
  }, {});

  // 1. Convert raw data to array of { date, profitLoss }
  const graphData = data.map(row => {
    const outcome = row.outcome ? row.outcome.trim().toLowerCase() : '';
    const stake = parseFloat(userBetsMap[row.bet_id] ?? 0);

    let profitLoss = 0;
    if (!outcome || outcome === 'pending') {
      profitLoss = 0;
    } else if (outcome === 'yes') {
      profitLoss = (parseFloat(row.odds) - 1) * stake;
    } else if (outcome === 'no') {
      profitLoss = -stake;
    }

    const dateStr = format(parseISO(row.game_date), 'yyyy-MM-dd');
    return { date: dateStr, profitLoss };
  });

  // 2. Aggregate by date
  const dailyData = graphData.reduce((acc, curr) => {
    const existing = acc.find(d => d.date === curr.date);
    if (existing) {
      existing.profitLoss += curr.profitLoss;
    } else {
      acc.push({ date: curr.date, profitLoss: curr.profitLoss });
    }
    return acc;
  }, []);

  // 3. Sort by date
  dailyData.sort((a, b) => new Date(a.date) - new Date(b.date));

  // 4. Compute cumulativeProfit
  let runningTotal = 0;
  const finalData = dailyData.map(item => {
    runningTotal += item.profitLoss;
    return {
      date: item.date,
      cumulativeProfit: runningTotal
    };
  });

  // If filterType is 'all' or 'last30Days', we skip labels dynamically.
  const skipLabels = (filterType === 'all' || filterType === 'last30Days');

  // We'll show ~10 labeled ticks if skipLabels is true
  const MAX_LABELS = 10;
  const totalPoints = finalData.length;
  const skipInterval = Math.ceil(totalPoints / MAX_LABELS);

  return (
    <div className="profit-loss-container">
      <h2 className="graph-title">Daily Profit/Loss</h2>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={finalData} margin={{ top: 20, right: 30, left: 20, bottom: 0 }}>
          <defs>
            <linearGradient id="profitLossGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#1CA733" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#ff6b6b" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#2e2e2e" />
          <XAxis
            dataKey="date"
            tick={{ fill: '#b0b0b0' }}
            tickFormatter={(dateStr, index) => {
              if (skipLabels) {
                // Show a label only if index is multiple of skipInterval
                return (index % skipInterval === 0)
                  ? format(parseISO(dateStr), 'MMM d')
                  : '';
              } else {
                // Show all date labels
                return format(parseISO(dateStr), 'MMM d');
              }
            }}
          />
          <YAxis tick={{ fill: '#b0b0b0' }} />
          <Tooltip
            contentStyle={{
              backgroundColor: '#333',
              color: '#fff',
              borderRadius: '5px'
            }}
            labelFormatter={(label) => format(parseISO(label), 'PPP')}
          />
          <Area
            type="monotone"
            dataKey="cumulativeProfit"
            stroke="#82ca9d"
            strokeWidth={2}
            fill="url(#profitLossGradient)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProfitLossGraph;