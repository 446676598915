import React, { useState } from 'react';
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import '../css/EditBetModal.css';

const EditBetModal = ({ bet, onClose, onSave, onDelete }) => {
    const [editedBet, setEditedBet] = useState({
        stat_line: bet.stat_line,
        odds: bet.odds,
        outcome: bet.outcome,
        unit_placed: bet.unit_placed || "", // Include unit_placed
    });

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleChange = (e) => {
        setEditedBet({ ...editedBet, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        onSave(editedBet); // Pass edited bet back to parent component
    };

    const handleDelete = () => {
        setShowConfirmModal(true); // Show confirmation modal
      };

    const confirmDelete = () => {
        onDelete(bet.bet_id); // Call delete function
        setShowConfirmModal(false);
    };

    if (!bet) return null; // Don't render if no bet is selected

    return (
        <div className="modal-overlay">
        <div className="modal-content">
            {/* Non-editable Fields */}
            <div className="non-editable">
            <p><strong>Date:</strong> {bet.game_date}</p>
            <p><strong>Round:</strong> {bet.round}</p>
            <p><strong>Home Team:</strong> {bet.home_team}</p>
            <p><strong>Away Team:</strong> {bet.away_team}</p>
            <p><strong>Player Name / Team:</strong> {bet.name}</p>
            <p><strong>Stat Type / Bet Type:</strong> {bet.stat_type}</p>
            </div>

            {/* Editable Fields */}
            <label>Stat Line:</label>
            <input type="text" name="stat_line" value={editedBet.stat_line} onChange={handleChange} />

            <label>Odds:</label>
            <input type="text" name="odds" value={editedBet.odds} onChange={handleChange} />

            <label>Win/Loss:</label>
            <select name="outcome" value={editedBet.outcome} onChange={handleChange}>
            <option value="yes">Win</option>
            <option value="no">Loss</option>
            <option value="pending">Pending</option>
            </select>

            <label>Units Placed:</label>
            <input 
                type="number" 
                name="unit_placed" 
                value={editedBet.unit_placed} 
                onChange={handleChange} 
            />

            <div className="modal-buttons">
            <button className="save-button" onClick={handleSave}>Save</button>
            <button className="close-button" onClick={onClose}>Close</button>
            </div>

            {/* Delete Button */}
            <button className="delete-button" onClick={handleDelete}>Delete Bet</button>

            {/* Show Confirm Delete Modal */}
            {showConfirmModal && <ConfirmDeleteModal onConfirm={confirmDelete} onCancel={() => setShowConfirmModal(false)} />}

        </div>
        </div>
    );
    };

export default EditBetModal;