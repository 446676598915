import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import ResultsTable from '../components/ResultsTable';
import Spinner from '../components/Spinner';
import AddBetForm from '../components/AddBetForm';
import EditBetModal from '../components/EditBetModal'; 
import '../css/UserResultsHistory.css';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function UserResultsHistory() {
  const [userResults, setUserResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedBet, setSelectedBet] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  // Define fetchUserResults at the component level
  const fetchUserResults = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${BACKEND_URL}/api/results/user-results`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user results');
      }

      const data = await response.json();
      console.log("Fetched user results:", data);
      setUserResults(data.data || []);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };


  const handleSaveBet = async (editedBet) => {
    const { data, error } = await supabase.auth.getSession();
    
    if (error || !data?.session?.user?.id) {
        console.error("Error fetching user session or missing user ID:", error);
        return;
    }

    const userId = data.session.user.id;

    try {
        console.log("Saving bet with data:", {
            user_id: userId,
            bet_id: selectedBet.bet_id,
            stat_line: editedBet.stat_line,
            odds: editedBet.odds,
            outcome: editedBet.outcome,
            game_date: selectedBet.game_date,
            round: selectedBet.round,
            home_team: selectedBet.home_team,
            away_team: selectedBet.away_team,
            name: selectedBet.name,
            stat_type: selectedBet.stat_type,
            unit_placed: editedBet.unit_placed
        });

        const response = await fetch(`${BACKEND_URL}/api/results/save-edited-bet`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: userId,
                bet_id: selectedBet.bet_id,
                stat_line: editedBet.stat_line,
                odds: editedBet.odds,
                outcome: editedBet.outcome,
                game_date: selectedBet.game_date,
                round: selectedBet.round,
                home_team: selectedBet.home_team,
                away_team: selectedBet.away_team,
                name: selectedBet.name,
                stat_type: selectedBet.stat_type,
                unit_placed: editedBet.unit_placed
            }),
        });

        const data = await response.json();
        if (data.success) {
            setShowEditModal(false);
            fetchUserResults(); // Refresh results after saving
        } else {
            console.error("Failed to save edited bet:", data.message);
        }
    } catch (error) {
        console.error("Error saving bet:", error);
    }
};


const handleDeleteBet = async (betId) => {
    const { data, error } = await supabase.auth.getSession();

    if (error || !data?.session?.user?.id) {
        console.error("Error fetching user session or missing user ID:", error);
        return;
    }

    const userId = data.session.user.id;

    console.log("Deleting bet with:", { user_id: userId, bet_id: betId });

    try {
      const response = await fetch(`${BACKEND_URL}/api/results/delete-bet`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: userId, 
          bet_id: betId 
        }),
      });

      const responseData = await response.json();
      if (response.ok && responseData.success) {
        setShowEditModal(false);
        fetchUserResults(); // Refresh the table
      } else {
        console.error("Failed to delete bet:", responseData.message);
      }
    } catch (error) {
      console.error("Error deleting bet:", error);
    }
};


  // Call fetchUserResults on component mount
  useEffect(() => {
    fetchUserResults();
  }, []);

  const handleAddBet = async (newBet) => {
    await fetchUserResults(); // Re-fetch user results from the backend
    setShowModal(false); // Then close the modal
  };

  const handleEditBet = (bet) => {
    setSelectedBet(bet);
    setShowEditModal(true);
  };

  // Handlers to toggle modal visibility
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <div className="button-container">
        <button className="add-bet-button" onClick={openModal}>Add Bet</button>
      </div>
      {showModal && (
        <div className="modal-overlay">
          <div className="add-bet-modal-content">
            <button className="modal-close" onClick={closeModal}>X</button>
            <AddBetForm onAddBet={handleAddBet} />
          </div>
        </div>
      )}
      <ResultsTable resultsData={userResults} onEditBet={handleEditBet} />
      {showEditModal && selectedBet && (
        <EditBetModal 
            bet={selectedBet} 
            onClose={() => setShowEditModal(false)} 
            onSave={handleSaveBet} 
            onDelete={handleDeleteBet}
        />
      )}
    </div>
  );
}

export default UserResultsHistory;