import React from 'react';
import ResetPass from '../components/ResetPassword';

function ResetPassword() {
    return (
        <div className="container">
            <ResetPass />
        </div>
    );
}

export default ResetPassword;
