import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../css/GameTile.css'; // Add any new styles specific to GameTile if needed
import { supabase } from '../supabaseClient';
import BetModal from './BetModal';
import GameGraph from './graphs/gameGraph'; // Import the GameGraph component

const teamLogos = {
    "Adelaide": "/images/AFL/adelaide.png",
    "Brisbane Lions": "/images/AFL/brisbane_lions.png",
    "Carlton": "/images/AFL/carlton.png",
    "Collingwood": "/images/AFL/collingwood.png",
    "Essendon": "/images/AFL/essendon.png",
    "Fremantle": "/images/AFL/fremantle.png",
    "Greater Western Sydney": "/images/AFL/giants.png",
    "Hawthorn": "/images/AFL/hawthorn.png",
    "Melbourne": "/images/AFL/melbourne.png",
    "North Melbourne": "/images/AFL/north_melbourne.png",
    "Richmond": "/images/AFL/richmond.png",
    "St Kilda": "/images/AFL/st_kilda.png",
    "Sydney": "/images/AFL/sydney_swans.png",
    "Western Bulldogs": "/images/AFL/western_bulldogs.png",
    "Gold Coast": "/images/AFL/gold_coast.png",
    "West Coast": "/images/AFL/west_coast.png",
    "Geelong": "/images/AFL/geelong.png",
    "Port Adelaide": "/images/AFL/port_adelaide.png",

    "Sea Eagles": "/images/NRL/sea_eagles.png",
    "Rabbitohs": "/images/NRL/rabbitohs.png",
    "Roosters": "/images/NRL/roosters.png",
    "Broncos": "/images/NRL/broncos.png",
    "Knights": "/images/NRL/knights.png",
    "Raiders": "/images/NRL/raiders.png",
    "Warriors": "/images/NRL/warriors.png",
    "Sharks": "/images/NRL/sharks.png",
    "Panthers": "/images/NRL/panthers.png",
    "Storm": "/images/NRL/storm.png",
    "Eels": "/images/NRL/eels.png",
    "Bulldogs": "/images/NRL/bulldogs.png",
    "Titans": "/images/NRL/titans.png",
    "Dragons": "/images/NRL/dragons.png",
    "Dolphins": "/images/NRL/Dolphins.png",
    "Cowboys": "/images/NRL/cowboys.png",
    "Wests Tigers": "/images/NRL/west_tigers.png",
    "Sea Eagles": "/images/NRL/sea_eagles.png"


};

const betLogos = {
    "SportsBet": "/images/Bookmakers/Sportsbet icon.jpeg",
    "PointsBet (AU)": "/images/Bookmakers/Pointsbet icon.png",
    "TAB": "/images/Bookmakers/TAB icon.png",
    "BlueBet": "/images/Bookmakers/Bluebet icon.jpeg",
    "Unibet": "/images/Bookmakers/Unibet.png",
    "Ladbrokes": "/images/Bookmakers/ladbrokes icon.jpeg",
    "Bet Right": "/images/Bookmakers/betright icon.png",
    "TABtouch": "/images/Bookmakers/TABtouch.jpeg",
    "PlayUp": "/images/Bookmakers/playup.jpeg",
    "TopSport": "/images/Bookmakers/TopSport.jpeg",
    "Betfair": "/images/Bookmakers/Betfair.png",
    "Betr": "/images/Bookmakers/betr.jpeg",
    "Neds": "/images/Bookmakers/Neds.jpeg",
    "BoomBet": "/images/Bookmakers/Boombet.png",
    "Bet365 AU": "/images/Bookmakers/Bet365 AU.png"
}

const weatherIcons = {
    "Clear": "/images/weather/clear-day.svg",
    "Cloudy": "/images/weather/cloudy.svg",
    "Drizzle": "images/weather/drizzle.svg",
    "Rain": "/images/weather/rain.svg"
}

const GameTile = ({ gameId, data, onExpand, index, expandedIndex, selectedSport, selectedBets, setSelectedBets }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [statsData, setStatsData] = useState({});
    const [loading, setLoading] = useState(false);
    const [gameStats, setGameStats] = useState(null); // For storing last 5 games stats
    const [venueStats, setVenueStats] = useState(null); // For storing venue performance stats
    const [weatherStats, setWeatherStats] = useState(null); // For storing weather performance stats
    const [error, setError] = useState(null); // For storing errors
    const [selectedGraphType, setSelectedGraphType] = useState('games');
    const [showBetModal, setShowBetModal] = useState(false);
    // console.log(data);

    
    useEffect(() => {
        setIsSelected(selectedBets.has(data.bet_id)); // Update state if bet is in `selectedBets`
    }, [selectedBets, data.bet_id]); // Runs when `selectedBets` changes

    const sportLogos = {
        AFL: "/images/AFL/afl.png",
        NRL: "/images/NRL/NRL New.png",
    };
    

    const handleGraphTypeChange = (type) => {
        setSelectedGraphType(type);
    };

    const statsConfig = {
        games: 'Team Performance (Last 5 Games)',
        venue: 'Venue Performance (Last 5 Games)',
        weather: 'Weather Performance (Last 5 Games)',
    };

    const resolvedGameId = data.GameID;

    console.log('Fetching stats for GameID:', resolvedGameId);

    const fetchStatsData = async (type, gameID) => {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/analytics/stats?game_id=${gameID}&type=${type}`; // Unified API URL

        try {
            const response = await axios.get(apiUrl);
            return response.data; // Return the data
        } catch (err) {
            setError(`Failed to fetch ${type} stats: ${err.message}`);
            return null; // Gracefully handle errors
        }
    };

    const fetchAllData = async () => {
        try {
            const gameData = await fetchStatsData('games', resolvedGameId);
            const venueData = await fetchStatsData('venue', resolvedGameId);
            const weatherData = await fetchStatsData('weather', resolvedGameId);

            setStatsData({
                games: gameData || [],
                venue: venueData || [],
                weather: weatherData || [],
            });

            setGameStats(gameData || []);
            setVenueStats(venueData || []);
            setWeatherStats(weatherData || []);

            setLoading(false); // Set loading to false after data is fetched
        } catch (err) {
            console.error('Failed to fetch data:', err);
            setError(true);
        }
    };

    useEffect(() => {
        if (resolvedGameId) {
            setLoading(true); // Start loading
            fetchAllData(); // Fetch data
        }
    }, [resolvedGameId]);


    console.log('Game Stats:', gameStats);
    console.log('Venue Stats:', venueStats);
    console.log('Weather Stats:', weatherStats);
        
    
    const toggleExpand = () => {

        // Don't allow collapsing while the bet modal is open
        if (showBetModal) {
            return;
        }

        setIsExpanded((prev) => {
            const nextState = !prev;
    
            // Apply or remove the blur effect on the tiles-container
            const tileContainer = tileRef.current.closest('.tiles-container'); // Get the parent container
            if (tileContainer) {
                if (nextState) {
                    tileContainer.classList.add('toggle'); // Add class to blur other tiles
                } else {
                    tileContainer.classList.remove('toggle');
                }
            }
    
            return nextState;
        });
    };
    
    
    const toggleFlip = (event) => {
        if (event) {
            event.stopPropagation(); // Prevent triggering expand
        }
        setIsFlipped((prev) => !prev); // Toggle flip state
    };

    // Ref to the tile element to calculate its position on screen
    const tileRef = React.useRef();


    // 1) If bet is NOT selected -> open modal to add
    // 2) If bet IS selected -> remove bet
    const handleButtonClick = async (event) => {
        event.stopPropagation();
    
        if (!isSelected) {
        // Expand tile if you want it expanded before adding
        if (!isExpanded) {
            setIsExpanded(true);
        }
        // Show the bet modal
        setShowBetModal(true);
        } else {
        // Remove the bet
        await removeBetFromAccount();
        setIsSelected(false);
        setSelectedBets((prev) => {
            const updatedBets = new Set(prev);
            updatedBets.delete(data.bet_id);
            return updatedBets;
        });
        }
    };

    // Called by the modal's Confirm button
    const handleConfirmBet = async (betAmount) => {
        // 1. Actually add the bet
        await addBetToAccount(betAmount);

        // 2. Update local states
        setIsSelected(true);
        setSelectedBets((prev) => new Set(prev).add(data.bet_id));

        // 3. Close the modal
        setShowBetModal(false);
    };

    // Called by the modal's Cancel button
    const handleCancelBet = () => {
        setShowBetModal(false);
    };

    const removeBetFromAccount = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                alert('User is not logged in. Please log in first.');
                return;
            }
    
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user-bets/remove`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ bet_id: data.bet_id }),
            });
    
            if (!response.ok) {
                const result = await response.json();
                console.error('Error removing bet:', result.message);
                alert('Failed to remove bet: ' + result.message);
            } else {
                console.log('Bet removed successfully');
            }
        } catch (err) {
            console.error('Error removing bet:', err.message);
            alert('An error occurred while removing the bet.');
        }
    };


    // Automatically unflip when tile is unexpanded
    useEffect(() => {
        if (!isExpanded) {
            setIsFlipped(false);
        }
    }, [isExpanded]);


    const addBetToAccount = async (betAmount) => {
        try {
            const token = localStorage.getItem('token'); // Ensure the token is stored in localStorage
            if (!token) {
                alert('User is not logged in. Please log in first.');
                return;
            }
    
            // Fetch the logged-in user's ID from Supabase
            const { data: { user }, error: userError } = await supabase.auth.getUser();
            if (userError || !user) {
                console.error("Error fetching user ID:", userError?.message);
                alert('Error fetching user ID.');
                return;
            }
            const userId = user.id;
            console.log("Fetched user ID:", userId); // Log userId directly
    
            console.log('Tile Data:', data);
            console.log('Bet ID:', data.bet_id);
    
            if (!data.bet_id) {
                console.error('Error: bet_id is missing in data.');
                alert('Failed to add bet: Missing bet information.');
                return;
            }
    
            // Determine if it's a Player Bet or Game Bet (checking specific properties)
            const isPlayerBet = data.PlayerName !== undefined; // Player bets have a player name
    
            const betData = {
                bet_id: data.bet_id,
                home_team_id: data.HomeTeam || "N/A",
                away_team_id: data.AwayTeam || "N/A",
                game_date: data.GameDate || null,
                bookie: data.Bookie || "N/A",
                odds: data.Odds || null,
                selected_at: new Date().toISOString(),
                player_name: isPlayerBet ? data.PlayerName : "N/A",
                bet_type: data.BetStatType || "N/A",
                stake: betAmount
            };
    
            console.log("Sending Bet Data:", betData);
    
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user-bets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userId,
                    betData
                }),
            });
    
            const result = await response.json();
            console.log('result '+result.betData);
    
            if (!response.ok) {
                console.error('Error adding bet:', result.message);
            } else {
                console.log('Bet added successfully:', result);
            }
        } catch (err) {
            console.error('Error adding bet:', err.message);
        }
    };

    const getDisplayTeamName = (teamName) => {
        return teamName === "Greater Western Sydney" ? "GWS" : teamName;
    };


    // helper function to fetch team logos to add to cards
    const getTeamLogo = (teamName) => {
        return teamLogos[teamName]
        };

    const getBookieLogo = (bookieName) => {
        return betLogos[bookieName]
    }

    const getWeatherIcon = (weather) => {
        return weatherIcons[weather]
    }

    return (
        <div
            ref={tileRef}
            className={`gametile ${isExpanded ? 'expanded' : ''} ${isFlipped ? 'flipped' : ''}`}
            onClick={toggleExpand} // Use local toggleExpand function
        >

            {!isFlipped ? (
                // Front ****************
            <>
                <div className="tile-header">
                        <div className="tile-header-left">
                            <img
                                src={sportLogos[selectedSport]}
                                alt={`${selectedSport} Logo`}
                                className="sport-icon"
                            />
                        </div>

                        <div className="tile-header-middle">
                            <div className="date-container">
                                <span className="game-date">
                                    {data.GameDate
                                        ? new Date(data.GameDate).toLocaleDateString('en-GB', {
                                            weekday: 'short',
                                            day: '2-digit',
                                            month: 'short',
                                        })
                                        : 'N/A'}
                                </span>
                            </div>
                        </div>

                        <div className="tile-header-right">
                            <button
                                onClick={handleButtonClick}
                                className={`tile-button ${isSelected ? "selected" : ""}`}
                            >
                                {isSelected ? "\u00D7" : "+"}
                            </button>

                            {/* Conditionally render the modal if needed */}
                            {showBetModal && (
                                <BetModal
                                onConfirm={handleConfirmBet}
                                onCancel={handleCancelBet}
                                />
                            )}
                        </div>
                    </div>

                    <div className="gametile-gameInfo">
                        <img
                            src={getTeamLogo(data.HomeTeam.replace(/_/g, " "))}
                            alt={`${data.HomeTeam} logo`}
                            className="team-logo"
                        />
                        {isExpanded && (
                            <div className="gametile-homeTeam">
                                <span className="team-names home-team-names">
                                    {getDisplayTeamName(data.HomeTeam.replace(/_/g, " "))}
                                </span>
                            </div>
                        )}
                        <div className='vs-text'>
                            {" vs "}
                        </div>
                        {isExpanded && (
                            <div className="gametile-awayTeam">
                                <span className="team-names away-team-names">
                                    {getDisplayTeamName(data.AwayTeam.replace(/_/g, " "))}
                                </span>
                            </div>
                        )}
                        <img
                            src={getTeamLogo(data.AwayTeam.replace(/_/g, " "))}
                            alt={`${data.AwayTeam} logo`}l
                            className="team-logo"
                        />
                    </div>

                    <div className="gametile-content">
                        {/* Prediction Section */}
                        <div className="prediction-summary">
                            <div className="bet-info">
                                <div className="bet-team-logo">
                                    <img
                                        src={
                                            data.TeamID === data.HomeTeam
                                                ? getTeamLogo(data.HomeTeam.replace(/_/g, " "))
                                                : getTeamLogo(data.AwayTeam.replace(/_/g, " "))
                                        }
                                        alt={`Bet on ${
                                            data.TeamID === data.HomeTeam ? data.HomeTeam : data.AwayTeam
                                        }`}
                                    />
                                </div>
                                {data.BetType === "None" ? (
                                    <>
                                        <p style={{ marginRight: "17px" }}>To Win</p>
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            {parseFloat(data.BetType) > 0 ? "+" : ""}
                                        </p>
                                        <p>{data.BetType}</p>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="bet-block">
                            <div className="bet-info">
                                <img src={"/images/icon.jpg"} alt="Moolah Icon" className="moolah-icon" />
                                {`${(1 / (parseFloat(data.Model_probability) / 100)).toFixed(2)}`}
                            </ div>
                            {/* Our predicted odds and the market odds are displayed in this section */}
                            <p className="game-odds-line">
                                <img src={getBookieLogo(data.Bookie)} alt={`${data.Bookie}`} className="game-bookie-logo" /> 
                                {data.Odds}
                            </p>
                        </div>
                    </div>

                    {/* Extra content for expanded tile */}
                    {isExpanded && (
                            <div className="tile-extra">
                                <div className="game-stats-container">
                                    {/* Weather Icon */}
                                    { data.Weather && data.Weather !== 'N/A' && (
                                    <div className="weather-container">
                                        <img
                                        src={weatherIcons[data.Weather] || '/icons/weather/default.svg'}
                                        alt={data.Weather}
                                        className="weather-icon"
                                        />
                                        <span className="weather-text">{data.Weather}</span>
                                    </div>
                                    )}
                                    {/* Risk Analysis Button with Tooltip */}
                                    <div
                                            className={`risk-level-button ${
                                                data.Model_probability < 65
                                                    ? 'high-risk-card'
                                                    : data.Model_probability < 80
                                                    ? 'moderate-risk-card'
                                                    : 'low-risk-card'
                                            }`}
                                        >
                                            {data.Model_probability < 65
                                                ? 'High Risk'
                                                : data.Model_probability < 80
                                                ? 'Moderate Risk'
                                                : 'Low Risk'}

                                            {/* Tooltip Text */}
                                            <span className="tooltip-text">
                                                {data.Model_probability < 65
                                                    ? 'high-risk. We reccomend 0.25 normal unit'
                                                    : data.Model_probability < 80
                                                    ? 'Moderate risk. We reccomend 0.5 normal unit'
                                                    : 'Low risk. High confidence in predictions.'}
                                            </span>
                                        </div>
                                </div>

                                {/* Probabilities Section */}
                                <div className="stats-table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="stat-label">Model Probability:</td>
                                                <td className="stat-value">{data.Model_probability?.toFixed(2)}%</td>
                                            </tr>
                                            <tr>
                                                <td className="stat-label">Implied Probability:</td>
                                                <td className="stat-value">{data.Implied_probability?.toFixed(2)}%</td>
                                            </tr>
                                            <tr>
                                                <td className="stat-label">Market Advantage:</td>
                                                <td
                                                    className={`stat-value ${
                                                        data.Model_probability - data.Implied_probability > 0 ? 'positive' : 'negative'
                                                    }`}
                                                >
                                                    {(data.Model_probability - data.Implied_probability).toFixed(2)}%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="stat-label">Expected Value (EV):</td>
                                                <td className="stat-value">{(((data.Odds / (1 / (parseFloat(data.Model_probability) / 100)))-1)*100).toFixed(2)}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {/* Add right edge button */}
                        {isExpanded && (
                            <div
                                className="tile-right-edge-button"
                                onClick={(event) => {
                                    if (event) event.stopPropagation(); // Prevent click event from propagating
                                    toggleFlip(event);
                                }}
                            >
                                <span className="flip-icon">{'>'}</span>
                            </div>
                        )}
            </>
            ) : (
                /// Back ********************************************************************************************************
                <div className="gametile-back">
                    {isExpanded && (
                        <div className="gametile-back">
                            {isExpanded && (
                                <div
                                    className="gametile-right-edge-button"
                                    onClick={(event) => {
                                        if (event) event.stopPropagation();
                                        toggleFlip(event); // Ensure this function exists and works
                                    }}
                                >
                                    <span className="game-flip-icon">{'<'}</span>
                                </div>
                            )}
                        
                            {/* Show "Coming Soon" if NRL is selected */}
                            {selectedSport === "NRL" ? (
                                <div className="coming-soon-message">
                                    <p>NRL stats are coming soon!</p>
                                </div>
                            ) : (
                                !loading && !error && statsData && (
                                    <GameGraph dataSets={statsData} stats={statsConfig} />
                                )
                            )}
                        </div>
                    )}
                    

                    {!loading && !error && statsData && (
                        <GameGraph dataSets={statsData} stats={statsConfig} />
                    )}
                </div>
            )}

        </div>
    );
}

export default GameTile;