import React, { useEffect } from 'react';
import axios from 'axios';
import { supabase } from '../supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/PlansPage.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const plans = [
  // { name: 'Weekly', priceId: 'price_1QnEsnB39d9MJKdG4HK3RkFb', price: '$27.00/Week', mode: 'subscription', features: ['Full access to our AFL predictions', 'Bet tracking tools and risk profile calculators', 'Community support'] },
  // { name: 'Monthly', priceId: 'price_1QnEskB39d9MJKdGbhCCeeHU', price: '$77.00/month', mode: 'subscription', features: ['Full access to our AFL predictions', 'Bet tracking tools and risk profile calculators', 'Access to our multibet creator algorithms'] },
  // { name: 'Yearly', priceId: 'price_1QnEsgB39d9MJKdGLDldb4UX', price: '$770.00/Year', mode: 'subscription', features: ['Full access to our AFL predictions', 'Bet tracking tools and risk profile calculators', 'Access to our multibet creator algorithms'] },
  // { name: 'Day Pass', priceId: 'price_1QnbMoB39d9MJKdGesZ8UDxk', price: '$12.00/Day', mode: 'payment', features: ['Full access to our premium services', 'Try risk free for a full 24 hours', 'Community support'] },

  { name: 'Weekly', priceId: 'price_1QxpavB39d9MJKdGNIIDk0gr', price: '$13.00/Week', mode: 'subscription', message: ['7 Day Free Trial'], features: ['Full access to all of our predictions', 'Bet tracking tools and risk profile calculators', 'Community support'] },
  { name: 'Monthly', priceId: 'price_1Qxpb9B39d9MJKdGIPWtDbPx', price: '$33.00/month', mode: 'subscription', message: ['7 Day Free Trial'], features: ['Full access to all of our predictions', 'Bet tracking tools and risk profile calculators', 'Input into future sports added'] },
  { name: 'Yearly', priceId: 'price_1QxpbSB39d9MJKdGN7kKLtZ8', price: '$330.00/Year', mode: 'subscription', message: ['7 Day Free Trial'], features: ['Full access to all of our predictions', 'Bet tracking tools and risk profile calculators', 'Input into future features and sports added'] },
  { name: 'Day Pass', priceId: 'price_1QnbMoB39d9MJKdGesZ8UDxk', price: '$12.00/Day', mode: 'payment', features: ['Full access to all of our predictions', 'Try risk free for a full 24 hours', 'Community support'] },
];

function PlansPage() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const params = new URLSearchParams(location.search);
  const isChangeMode = params.get("change") === "true";
  const subscriptionId = params.get("subscriptionId");

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error("Error fetching session:", error.message);
      } else if (!session) {
        console.log("No session found on page load.");
        alert("Session expired, please log in again.");
        navigate('/login');
      } else {
        console.log("Current Supabase session:", session);
      }
    };

    checkSession();
  }, [navigate]);

  const handleSubscribe = async (priceId, mode) => {
    try {
      console.log("Checking if user is logged in...");
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();

      if (sessionError || !session) {
        console.error("No active session found:", sessionError?.message);
        alert("Please log in to subscribe to a plan.");
        navigate('/login');
        return;
      }

      console.log("User is logged in:", session.user);

      if (isChangeMode && subscriptionId) {
        const response = await axios.post(`${BACKEND_URL}/stripe/change-subscription`, {
          subscriptionId,
          newPlanId: priceId,
        });

        if (response.data.message) {
          alert(response.data.message);
        } else {
          alert("Subscription changed successfully");
        }
        navigate('/settings');
      } else {
        const response = await axios.post(`${BACKEND_URL}/stripe/create-checkout-session`, {
          priceId,
          mode,
          userId: session.user.id,
        });

        console.log("Checkout session created:", response.data.url);
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error("Error in handleSubscribe:", error);
      alert("Failed to create or change subscription");
    }
  };

  return (
    <div className="plans-page">
      <h2>{isChangeMode ? "Change Your Plan" : "Select a Plan"}</h2>
      <div className="plans-container">
        {plans.map((plan, index) => (
          <div key={plan.priceId} className={`plan-card ${index === 3 ? 'wider' : ''}`}>
            <h3 className="plan-title">{plan.name}</h3>
            <p className="plan-price">{plan.price}</p>
            <p className="trial-message">{plan.message}</p>
            <ul className="plan-features">
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <button
              className="subscribe-btn"
              onClick={() => handleSubscribe(plan.priceId, plan.mode)}
            >
              {isChangeMode ? "Switch to this Plan" : "Subscribe"}
            </button>
          </div>
        ))}
      </div>
      <div className="features-container">
      </div>
    </div>
  );
}

export default PlansPage;
