import React from "react";
import {
    Bar,
    BarChart,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import '../../css/Results.css';

const MainResultsGraph = ({ resultsData, selectedSport }) => {

    // Function to filter results based on selected sport (NRL or AFL)
    const filterBySport = (sport) => {
        return resultsData.filter((bet) =>
            sport === "NRL" ? bet.table.includes("nrl") : bet.table.includes("TeamBets") || bet.table.includes("PlayerOdds")
        );
    };

    // Sample bet data mapping by sport type (NRL and AFL)
    const statTypeColorMap = {
        "player disposals over": "#a6d854",
        "player goal scorer anytime": "#e8a838",
        "spreads": "#61cdbb",
        "h2h": "#f47560",
    };

    // Function to determine the color for a bet type
    const getColorForStatType = (statType) => {
        return statTypeColorMap[statType] || "#888888"; // fallback color
    };

    if (!resultsData.length) return (
        <div className="graphTileBorder">
            <div className="emptyTile">
                <div>
                    <p>No data for this date range</p>
                </div>
            </div>
        </div>
    )

    // Get filtered data based on the selected sport
    const filteredData = filterBySport(selectedSport);

    // Process Data
    const groupedData = {};
    filteredData.forEach((bet) => {
        const betType = bet.StatType;
        const win = bet.WinLoss === "yes" ? 1 : 0;

        if (!groupedData[betType]) {
            groupedData[betType] = {
                betType: betType.replace(/_/g, " "),
                totalBets: 0,
                totalWins: 0
            };
        }

        groupedData[betType].totalBets += 1;
        groupedData[betType].totalWins += win;
    });

    const formattedData = Object.values(groupedData).map((entry) => ({
        betType: entry.betType,
        accuracy: ((entry.totalWins / entry.totalBets) * 100).toFixed(2)
    }));

    return (
        <div className=" graphTileBorder">
            <div className="graphTile">
                <div className="tileHeader">
                    <h2>Bet Accuracy</h2>
                </div>

                <div className="offsetGraphs">
                    <ResponsiveContainer width="100%" height={250}>
                        <BarChart data={formattedData} layout="vertical">
                            <XAxis type="number" />
                            <YAxis dataKey="betType" type="category" width={200} />
                            <Tooltip />
                            <Bar dataKey="accuracy" name="Accuracy (%)">
                                {formattedData.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={getColorForStatType(entry.betType)}
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default MainResultsGraph;