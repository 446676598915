import React from "react";
import "../css/ResultsTable.css";

const removeUnderscores = (value) => {
  if (!value || typeof value !== "string") return value;
  return value.replace(/_/g, " ");
};

const ResultsTable = ({ resultsData, onEditBet }) => {
  if (!resultsData || resultsData.length === 0) {
    return <p>No data available to display.</p>;
  }

  return (
    <div id="custom-results-table-container">
      {/* Table View for Desktop */}
      <div className="desktop-view">
        <table className="custom-results-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Round</th>
              <th>Home Team</th>
              <th>Away Team</th>
              <th>Player Name / Team</th>
              <th>Stat Type / Bet Type</th>
              <th>Stat Line</th>
              <th>Odds</th>
              <th>Prediction</th>
              <th>Win/Loss</th>
            </tr>
          </thead>
          <tbody>
            {resultsData.map((result, index) => {
              const outcomeValue = result.WinLoss || result.outcome;
              let displayOutcome = outcomeValue;
              let backgroundColor = "transparent";

              if (outcomeValue && outcomeValue.toLowerCase() === "yes") {
                displayOutcome = "Win";
                backgroundColor = "rgba(28, 167, 51, 0.2)"; // Green
              } else if (outcomeValue && outcomeValue.toLowerCase() === "no") {
                displayOutcome = "Loss";
                backgroundColor = "rgba(211, 47, 47, 0.2)"; // Red
              }

              return (
                <tr key={index} className="custom-table-row">
                  <td>{result.game_date || "N/A"}</td>
                  <td>{result.round || "N/A"}</td>
                  <td>{removeUnderscores(result.home_team || "N/A")}</td>
                  <td>{removeUnderscores(result.away_team || "N/A")}</td>
                  <td>{removeUnderscores(result.name || "N/A")}</td>
                  <td>{removeUnderscores(result.stat_type || "N/A")}</td>
                  <td>{result.stat_line || "N/A"}</td>
                  <td>${result.odds || "N/A"}</td>
                  <td>
                    {typeof result.model_probability === "number"
                      ? result.model_probability.toFixed(2)
                      : "N/A"}
                  </td>
                  <td
                    className={
                      outcomeValue && outcomeValue.toLowerCase() === "yes"
                        ? "green-highlight1"
                        : outcomeValue && outcomeValue.toLowerCase() === "no"
                        ? "red-highlight1"
                        : "yellow-highlight1"
                    }
                    style={{ backgroundColor }}
                  >
                    {displayOutcome || "Pending"}
                  </td>
                  <td className="edit-column">
                    <button
                      className="edit-button"
                      onClick={() => onEditBet(result)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Mobile Card View */}
      <div className="mobile-view">
        {resultsData.map((result, index) => {
          const outcomeValue = result.WinLoss || result.outcome;
          let displayOutcome = outcomeValue;
          let outcomeClass = "";

          if (outcomeValue && outcomeValue.toLowerCase() === "yes") {
            displayOutcome = "Win";
            outcomeClass = "win";
          } else if (outcomeValue && outcomeValue.toLowerCase() === "no") {
            displayOutcome = "Loss";
            outcomeClass = "loss";
          }

          return (
            <div key={index} className="mobile-card">
              <div className="card-header">
                <p className="bet-amount">${result.odds || "N/A"}</p>
                <span className={`bet-status ${outcomeClass}`}>
                  {displayOutcome || "Pending"}
                </span>
              </div>
              <div className="card-body">
                <p>
                  <strong>Date:</strong> {result.game_date || "N/A"}
                </p>
                <p>
                  <strong>Round:</strong> {result.round || "N/A"}
                </p>
                <p>
                  <strong>Teams:</strong>{" "}
                  {removeUnderscores(result.home_team || "N/A")} vs{" "}
                  {removeUnderscores(result.away_team || "N/A")}
                </p>
                <p>
                  <strong>Player/Team:</strong> {removeUnderscores(result.name || "N/A")}
                </p>
                <p>
                  <strong>Stat Type:</strong> {removeUnderscores(result.stat_type || "N/A")}
                </p>
                <p>
                  <strong>Stat Line:</strong> {result.stat_line || "N/A"}
                </p>
                <button className="edit-button" onClick={() => onEditBet(result)}>
                  Edit
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ResultsTable;