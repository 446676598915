import React, { useState } from 'react';
import '../css/AddBetForm.css'; // Import your CSS for styling

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function AddBetForm({ onAddBet }) {
  const [formData, setFormData] = useState({
    game_date: '',
    round: '',
    home_team: '',
    away_team: '',
    odds: '',
    outcome: 'Pending',
    unit_placed: '',
    // Hard-coded or default fields (not shown in the form)
    stat_type: 'Multi Bet',
    player_name: 'N/A',
    stat_line: 'N/A',
    prediction: 'N/A',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BACKEND_URL}/api/results/add-custom-user-bet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) throw new Error('Failed to add new bet');

      const newBet = await response.json();
      onAddBet(newBet.data);

      // Reset form fields after submission
      setFormData({
        game_date: '',
        round: '',
        home_team: '',
        away_team: '',
        odds: '',
        unit_placed: '',
        outcome: 'Pending',
        stat_type: 'Multi Bet',
        player_name: 'N/A',
        stat_line: 'N/A',
        prediction: 'N/A',
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div id="addBetFormContainer">
        <form className="add-bet-form" onSubmit={handleSubmit}>
        <input
            type="date"
            name="game_date"
            value={formData.game_date}
            onChange={handleChange}
            required
        />

        <input
            type="text"
            name="round"
            placeholder="Round"
            value={formData.round}
            onChange={handleChange}
            required
        />

        <input
            type="text"
            name="home_team"
            placeholder="Home Team"
            value={formData.home_team}
            onChange={handleChange}
            required
        />

        <input
            type="text"
            name="away_team"
            placeholder="Away Team"
            value={formData.away_team}
            onChange={handleChange}
            required
        />

        <input
            type="number"
            step="0.01"
            name="odds"
            placeholder="Odds"
            value={formData.odds}
            onChange={handleChange}
            required
        />

        <select
            name="outcome"
            value={formData.outcome}
            onChange={handleChange}
        >
            <option value="Pending">Pending</option>
            <option value="Yes">Win</option>
            <option value="No">Loss</option>
        </select>

        <input
            type="number"
            step="0.01"
            name="unit_placed"
            placeholder="Units Placed"
            value={formData.unit_placed}
            onChange={handleChange}
            required
        />

        <button type="submit">Add Bet</button>
        </form>
    </div>
  );
}

export default AddBetForm;