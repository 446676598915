import React from 'react';
import { getTeamLogo, getBookieLogo } from '../../utils/logoHelpers';

const weatherIcons = {
    "Clear": "/images/weather/clear-day.svg",
    "Cloudy": "/images/weather/cloudy.svg",
    "Drizzle": "images/weather/drizzle.svg",
    "Rain": "/images/weather/rain.svg"
}

const getDisplayTeamName = (teamName) => {
  return teamName === "Greater Western Sydney" ? "GWS" : teamName;
};

function GamePickLayout({ data, isExpanded, toggleFlip }) {
  return (
    <>
      <div className="gametile-gameInfo">
        <img
          src={getTeamLogo(data.HomeTeam.replace(/_/g, ' '))}
          alt={`${data.HomeTeam} logo`}
          className="team-logo"
        />
        {isExpanded && (
          <div className="gametile-homeTeam">
            <span className="team-names home-team-names">
            {getDisplayTeamName(data.HomeTeam.replace(/_/g, " "))}
            </span>
          </div>
        )}
        <div className="vs-text">vs</div>
        {isExpanded && (
          <div className="gametile-awayTeam">
            <span className="team-names away-team-names">
            {getDisplayTeamName(data.AwayTeam.replace(/_/g, " "))}
            </span>
          </div>
        )}
        <img
          src={getTeamLogo(data.AwayTeam.replace(/_/g, ' '))}
          alt={`${data.AwayTeam} logo`}
          className="team-logo"
        />
      </div>

      {/* The content block, e.g. “To Win” or “+6.5”, etc. */}
      <div className="gametile-content">
        {/* Prediction Section */}
        <div className="prediction-summary">
            <div className="bet-info">
                <div className="bet-team-logo">
                    <img
                        src={
                            data.TeamID === data.HomeTeam
                                ? getTeamLogo(data.HomeTeam.replace(/_/g, " "))
                                : getTeamLogo(data.AwayTeam.replace(/_/g, " "))
                        }
                        alt={`Bet on ${
                            data.TeamID === data.HomeTeam ? data.HomeTeam : data.AwayTeam
                        }`}
                    />
                </div>
                {data.BetType === "None" ? (
                    <>
                        <p style={{ marginRight: "17px" }}>To Win</p>
                    </>
                ) : (
                    <>
                        <p>
                            {parseFloat(data.BetType) > 0 ? "+" : ""}
                        </p>
                        <p>{data.BetType}</p>
                    </>
                )}
            </div>
        </div>

        <div className="bet-block">
          <div className="bet-info">
          <img src={"/images/icon.jpg"} alt="Moolah Icon" className="moolah-icon" />
            {`${(1 / (parseFloat(data.Model_probability) / 100)).toFixed(2)}`}
          </div>
          <p className="game-odds-line">
            <img src={getBookieLogo(data.Bookie)} alt={data.Bookie}
            className="bookie-logo" />
            {data.Odds}
          </p>
        </div>
      </div>

      {/* If expanded, show advanced stuff */}
      {isExpanded && (
        <div className="tile-extra">
          <div className="game-stats-container">
            {/* Weather Icon */}
            { data.Weather && data.Weather !== 'N/A' && (
            <div className="weather-container">
                <img
                src={weatherIcons[data.Weather] || '/icons/weather/default.svg'}
                alt={data.Weather}
                className="weather-icon"
                />
                <span className="weather-text">{data.Weather}</span>
            </div>
            )}
            {/* Risk Analysis Button with Tooltip */}
            <div
                    className={`risk-level-button ${
                        data.Model_probability < 65
                            ? 'high-risk-card'
                            : data.Model_probability < 80
                            ? 'moderate-risk-card'
                            : 'low-risk-card'
                    }`}
                >
                    {data.Model_probability < 65
                        ? 'High Risk'
                        : data.Model_probability < 80
                        ? 'Moderate Risk'
                        : 'Low Risk'}

                    {/* Tooltip Text */}
                    <span className="tooltip-text">
                        {data.Model_probability < 65
                            ? 'high-risk. We reccomend 0.25 normal unit'
                            : data.Model_probability < 80
                            ? 'Moderate risk. We reccomend 0.5 normal unit'
                            : 'Low risk. High confidence in predictions.'}
                    </span>
                </div>
        </div>

        {/* Probabilities Section */}
        <div className="stats-table">
            <table>
                <tbody>
                    <tr>
                        <td className="stat-label">Model Probability:</td>
                        <td className="stat-value">{data.Model_probability?.toFixed(2)}%</td>
                    </tr>
                    <tr>
                        <td className="stat-label">Implied Probability:</td>
                        <td className="stat-value">{data.Implied_probability?.toFixed(2)}%</td>
                    </tr>
                    <tr>
                        <td className="stat-label">Market Advantage:</td>
                        <td
                            className={`stat-value ${
                                data.Model_probability - data.Implied_probability > 0 ? 'positive' : 'negative'
                            }`}
                        >
                            {(data.Model_probability - data.Implied_probability).toFixed(2)}%
                        </td>
                    </tr>
                    <tr>
                        <td className="stat-label">Expected Value (EV):</td>
                        <td className="stat-value">{(((data.Odds / (1 / (parseFloat(data.Model_probability) / 100)))-1)*100).toFixed(2)}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
      )}
    </>
  );
}

export default GamePickLayout;