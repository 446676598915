import '../css/Edge.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';

function EdgeSection() {

  const navigate = useNavigate(); // Initialize navigate function

  const handleGetStartedClick = () => {
    navigate('/signup'); // Navigate to the signup page
  };

  return (
    <section id="about" className="edge-section">
      <div className="edge-content">
        <h2>FIND YOUR EDGE</h2>
        <p>Gain a decisive edge over the bookmakers by finding targeted picks showing the best value across different sporting events</p>
        <button className="edge-button" onClick={handleGetStartedClick} >Get Started</button>
      </div>
      <div className="edge-video">
          <iframe
              src="https://www.youtube.com/embed/6Y1uipOzcfo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
          ></iframe>
      </div>
    </section>
  );
}

export default EdgeSection;