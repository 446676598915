import React from "react";
import '../../css/Results.css';

const ResultsTable = ({ resultsData, selectedSport }) => {

  // Function to filter results based on selected sport (NRL or AFL)
  const filterBySport = (sport) => {
    return resultsData.filter((bet) =>
      sport === "NRL" ? bet.table.includes("nrl") : bet.table.includes("TeamBets") || bet.table.includes("PlayerOdds")
    );
  };

  const statTypeColorMap = {
    "player disposals over": "#a6d854",
    "player goal scorer anytime": "#e8a838",
    "spreads": "#61cdbb",
    "h2h": "#f47560",
  };

  const getColorForStatType = (statType) => {
    return statTypeColorMap[statType] || "#888888"; // fallback color
  };

  if (!resultsData.length) return (
    <div className="graphTileBorder">
        <div className="emptyTile">
            <div>
                <p>No data for this date range</p>
            </div>
        </div>
    </div>
  )

  // Get filtered data based on the selected sport
  const filteredData = filterBySport(selectedSport);

  // Group by StatType (Bet Type)
  const groupedData = filteredData.reduce((acc, bet) => {
    const { StatType, OddsValue, WinLoss } = bet;

    // Initialize if not already done
    if (!acc[StatType]) {
      acc[StatType] = {
        totalBets: 0,
        totalWins: 0,
        totalProfit: 0
      };
    }

    // Count bets
    acc[StatType].totalBets += 1;

    // Count wins and calculate profit
    if (WinLoss === "yes") {
      acc[StatType].totalWins += 1;
      acc[StatType].totalProfit += OddsValue - 1;  // Win: Profit is (Odds - 1)
    } else {
      acc[StatType].totalProfit -= 1; // Loss: Subtract 1 unit
    }

    return acc;
  }, {});

  // Prepare data for rendering in table
  const tableData = Object.keys(groupedData).map((betType) => {
    const { totalBets, totalWins, totalProfit } = groupedData[betType];

    // Calculate accuracy percentage
    const accuracy = totalBets ? ((totalWins / totalBets) * 100).toFixed(2) : 0;
    return {
      betType: betType.replace(/_/g, " "), // Format bet type (e.g., "player_disposals_over" -> "player disposals over")
      totalBets,
      totalProfit: totalProfit.toFixed(2),
      totalWins,
      accuracy
    };
  });

  return (
    <div className="graphTileBorder">
      <div className="graphTile">
      <div className="tileHeader">
          <h2>Summary</h2>
      </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Bet Type</th>
                <th>Bets</th>
                <th>Total Unit Profit</th>
                <th>wins</th>
                <th>Win (%)</th>
                <th>Bet Efficiency</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => {
                const rowColor = getColorForStatType(row.betType);
                return (
                  <tr key={row.betType}>
                    <td style={{ color: rowColor }}>{row.betType}</td>
                    <td style={{ color: rowColor }}>{row.totalBets}</td>
                    <td style={{ color: rowColor }}>{row.totalProfit}</td>
                    <td style={{ color: rowColor }}>{row.totalWins}</td>
                    <td style={{ color: rowColor }}>{row.accuracy}</td>
                    <td style={{ color: rowColor }}>
                      {((row.totalProfit / row.totalBets) * 100).toFixed(2)}% ROI
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ResultsTable;
