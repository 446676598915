import '../css/Hero.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Hero() {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/signup');
  };

  return (
    <section id="hero" className="hero">
      <div className="hero-content">
        <h1 className="hero-heading">MACHINE LEARNING MEETS SPORTS BETTING</h1>
        <p className="hero-subtext">
          Our algorithms analyse past sporting events looking for an edge over the bookmakers
        </p>
        <button className="hero-button" onClick={handleGetStartedClick}>
          Get Started
        </button>
      </div>
      <div className="hero-phone">
        <img src="/images/mockup.png" alt="Phone Mockup" />
      </div>
    </section>
  );
}

export default Hero;