import React, { useEffect, useState } from "react";
import {
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import '../../css/Results.css';

const PerformanceGraph = ({ resultsData, selectedSport }) => {
    const [selectedGraph, setSelectedGraph] = useState("profit"); // "accuracy" or "profit"
    const [allBetTypes, setAllBetTypes] = useState([]);
    const [selectedBetTypes, setSelectedBetTypes] = useState([]);
    const [formattedData, setFormattedData] = useState([]);

    const statTypeColorMap = {
        "player_disposals_over": "#a6d854",
        "player_goal_scorer_anytime": "#e8a838",
        "spreads": "#61cdbb",
        "h2h": "#f47560",
    };

    const filterBySport = (sport) => {
        return resultsData.filter((bet) =>
            sport === "NRL" ? bet.table.includes("nrl") : bet.table.includes("TeamBets") || bet.table.includes("PlayerOdds")
        );
    };

    const getColorForStatType = (statType) => {
        return statTypeColorMap[statType] || "#888888"; // fallback color
    };

    const filteredData = filterBySport(selectedSport);

    useEffect(() => {
        if (!resultsData.length) return;

        // 1. Get unique dates (sorted)
        const uniqueDates = [
            ...new Set(resultsData.map((bet) => bet.GameDate))
        ].sort((a, b) => new Date(a) - new Date(b));

        // 2. Group bets by bet type and date
        const groupedData = {};
        filteredData.forEach((bet) => {
            const betType = bet.StatType;
            const date = bet.GameDate;
            const win = bet.WinLoss === "yes" ? 1 : 0;
            const odds = parseFloat(bet.OddsValue);

            if (!groupedData[betType]) {
                groupedData[betType] = {};
            }
            if (!groupedData[betType][date]) {
                groupedData[betType][date] = {
                    totalBets: 0,
                    totalWins: 0,
                    totalProfit: 0
                };
            }
            groupedData[betType][date].totalBets += 1;
            groupedData[betType][date].totalWins += win;
            groupedData[betType][date].totalProfit += win ? odds - 1 : -1;
        });

        // 3. Get full list of bet types and set default selection (all)
        const betTypes = Object.keys(groupedData);
        setAllBetTypes(betTypes);
        setSelectedBetTypes(betTypes);

        // 4. Build a single dataset with one entry per unique date.
        //    For each bet type, add the average profit and accuracy (or 0 if no bets)
        const newData = uniqueDates.map((date) => {
            const entry = { GameDate: date };
            betTypes.forEach((betType) => {
                if (groupedData[betType][date]) {
                    const stats = groupedData[betType][date];
                    entry[`${betType}_Profit`] = parseFloat(
                        (stats.totalProfit / stats.totalBets).toFixed(2)
                    );
                    entry[`${betType}_Accuracy`] = parseFloat(
                        ((stats.totalWins / stats.totalBets) * 100).toFixed(2)
                    );
                } else {
                    entry[`${betType}_Profit`] = null;
                    entry[`${betType}_Accuracy`] = null;
                }
            });
            return entry;
        });

        setFormattedData(newData);
    }, [resultsData, selectedSport]);

    if (!resultsData.length) return (
        <div className="graphTileBorder">
            <div className="emptyTile">
                <div>
                    <p>No data for this date range</p>
                </div>
            </div>
        </div>
    )

    // Toggle a bet type on/off
    const handleBetTypeToggle = (betType) => {
        setSelectedBetTypes((prevSelected) =>
            prevSelected.includes(betType)
                ? prevSelected.filter((type) => type !== betType)
                : [...prevSelected, betType]
        );
    };

    const getButtonStyle = (buttonType) => {
        return {
            backgroundColor: selectedGraph === buttonType ? "green" : "#707070"
        };
    };

    return (
        <div className="graphTileBorder">
            <div className="mainGraphTile">
            {/* Toggle Unit Profit / Accuracy */}
            <div className="mainGraphHeader">
                <div className="mainGraphHeaderText">
                    <h2>History Graph</h2>
                </div>
                    <div className="checkboxes">
                        <div className="HTButtons">
                            <button
                                onClick={() => setSelectedGraph("profit")}
                                style={getButtonStyle("profit")}
                            >
                                Unit Profit ($)
                            </button>
                            <button
                                onClick={() => setSelectedGraph("accuracy")}
                                style={getButtonStyle("accuracy")}
                            >
                                Accuracy (%)
                            </button>
                        </div>

                        <div className="HTCheckbox">
                            {/* Checkboxes for all bet types */}
                            {allBetTypes.map((type) => (
                                <label key={type}>
                                    <input
                                        type="checkbox"
                                        checked={selectedBetTypes.includes(type)}
                                        onChange={() => handleBetTypeToggle(type)}
                                    />
                                    {type.replace(/_/g, " ")}
                                </label>
                            ))}
                        </div>
                    </div>
            </div>

            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={formattedData}>
                    <XAxis
                        dataKey="GameDate"
                        tick={{ fill: "white" }}
                        tickFormatter={(tick) =>
                            new Date(tick).toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric"
                            })
                        }
                    />
                    <YAxis tick={{ fill: "white" }} />
                    <Tooltip
                        content={<CustomTooltip />}
                    />
                    <Legend />
                    {selectedBetTypes.map((type) => (
                        <Line
                            key={type}
                            type="monotone"
                            dot={false}
                            dataKey={
                                selectedGraph === "profit"
                                    ? `${type}_Profit`
                                    : `${type}_Accuracy`
                            }
                            stroke={getColorForStatType(type)}
                            name={`${type.replace(/_/g, " ")}`}
                            connectNulls={true}
                        />
                    ))}
                    <ReferenceLine
                        y="0" // change this to 'benchmark' if we want to include anytime goals
                        label={{
                            position: 'insideTopRight',
                            style: { fill: 'orange', fontSize: 12 },
                        }}
                        stroke="grey"
                        strokeDasharray="1 1"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
        </div>
    );
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ background: "black", padding: "10px", borderRadius: "5px", color: "white" }}>
                <p><b>Date:</b> {new Date(label).toLocaleDateString()}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ color: entry.color }}>
                        <b>{entry.name}:</b> {entry.value}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

export default PerformanceGraph;
