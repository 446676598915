import React, { useState } from 'react';
import '../css/SelectedBetsSlip.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Combined Odds calculation
const getCombinedOdds = (bets) => {
  const product = bets.reduce((acc, bet) => acc * (bet.Odds || 1), 1);
  return product.toFixed(2);
};

// Simple risk categorization
const getRiskLevel = (combinedOdds) => {
  const oddsNum = parseFloat(combinedOdds);
  if (oddsNum <= 2) return 'Low';
  if (oddsNum <= 4) return 'Medium';
  return 'High';
};

// Combined Probability (multiply decimal probabilities)
const getMultiProbability = (bets) => {
  const productDecimal = bets.reduce((acc, bet) => {
    const p = (bet.Model_probability || 0) / 100; // interpret as percentage
    return acc * p;
  }, 1);

  // Convert decimal to percentage string
  return (productDecimal * 100).toFixed(2);
};


const SelectedBetsSlip = ({ selectedBets, onRemoveBet, onMultiPlaced }) => {

  const [stake, setStake] = useState('');
  const [oddsTaken, setOddsTaken] = useState('');
  const [message, setMessage] = useState('');

  //remove underscores from data
  const formatName = (str) => (str ? str.replace(/_/g, ' ') : '');

  // Helper to determine Goals/Disposals label
  const getPlayerStatLabel = (bet) => {
    if (
      bet.BetStatType === "player_goals_scored_over" ||
      bet.BetStatType === "player_goal_scorer_anytime"
    ) {
      return "Goals";
    }
    if (bet.BetStatType === "player_disposals_over") {
      return "Disposals";
    }
    if (bet.BetStatType === "player_try_scorer_anytime") {
      return "Try Scorer";
    }
    return "N/A";
    
  };

  let combinedOdds = null;
  let multiRisk = null;
  let multiProb = null;
  if (selectedBets.length > 0) {
    combinedOdds = getCombinedOdds(selectedBets);
    multiRisk = getRiskLevel(combinedOdds);
    multiProb = getMultiProbability(selectedBets); // e.g. "73.45"
  }


  const handlePlaceMultiBet = async () => {
    // Construct an array of bet_ids from selectedBets
    const betIds = selectedBets.map((bet) => bet.bet_id);
    // You might also want to include a stake value; here we assume a default of 10 (or retrieve from state)
    const payload = {
      betIds,
      stake,
      combinedOdds: oddsTaken, 
      combinedProbability: multiProb,     
    };

    console.log("Payload:", payload);

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${BACKEND_URL}/api/user-bets/add-multi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        // Handle error (display error message, etc.)
        console.error("Error placing multi bet", response.statusText);
        setMessage('Error placing multi bet. Please try again.');
      } else {
        const data = await response.json();
        console.log("Multi bet placed successfully", data);
        setMessage('Multi bet placed successfully!');
        // Automatically clear the message after 3 seconds
        setTimeout(() => {
          setMessage('');
        }, 2000);
        // Clear the input fields
        setStake('');
        setOddsTaken('');
        // Optionally, notify the parent component that the multi bet was placed:
        if (onMultiPlaced) {
          onMultiPlaced(data);
        }
        // Clear selected bets if desired (this might be handled in the parent)
      }
    } catch (err) {
      console.error("Error placing multi bet", err);
    }
  };


  const displayStatLine = (bet) => {
    // If OverUnder is "Yes", ensure stat line is greater than 1
    if (bet.OverUnder === "Yes") {
      return Number(bet.StatLine) > 1 ? bet.StatLine : "0.5";
    }
    // Otherwise, simply return the stat line or fallback
    return bet.StatLine || "N/A";
  };


  return (
    <div className="bets-slip">
      <h2>BET SLIP</h2>
      {message && <p className="success-message">{message}</p>}
      {selectedBets.length === 0 ? (
        <p className='no-bets-message'>No bets selected</p>
      ) : (
        selectedBets.map((bet) => {
          // Format date as "Fri, 21 Mar"
          const formattedDate = bet.GameDate
            ? new Date(bet.GameDate).toLocaleDateString('en-GB', {
                weekday: 'short',
                day: '2-digit',
                month: 'short',
              })
            : 'N/A';

          // Probability as a percentage (assumes Model_probability is already a number)
          const probabilityStr = bet.Model_probability
            ? `${bet.Model_probability.toFixed(2)}%`
            : 'N/A';

          // Determine bet type based on available fields and BetCategory
          const isPlayerBet = !!bet.PlayerName;
          const isH2HBet = bet.BetCategory === 'h2h';
          const isSpreadBet = bet.BetCategory === 'spreads';

          // Replace underscores in team names / IDs
          const homeTeamFormatted = formatName(bet.HomeTeam);
          const awayTeamFormatted = formatName(bet.AwayTeam);
          const teamIdFormatted = formatName(bet.TeamID);

          

          return (
            <div key={bet.bet_id} className="bet-slip-item">
              <div className="selected-bet-info">
                {/* Match Info */}
                <div className="bet-match">
                  <span className="teams">
                  {homeTeamFormatted} vs {awayTeamFormatted}
                  </span>
                  <span className="game-date">{formattedDate}</span>
                </div>

                {/* Core Bet Details */}
                <div className="bet-details">
                  <span className="bet-label">
                  {isPlayerBet ? bet.PlayerName : teamIdFormatted || 'Unknown Bet'}
                  </span>
                  <span className="odds">Odds: {bet.Odds?.toFixed(2)}</span>
                  <span className="prob">Prob: {probabilityStr}</span>
                </div>

                {/* Additional Conditional Details */}
                <div className="bet-type">
                  {isPlayerBet && (
                    <span className="line-value">
                      {getPlayerStatLabel(bet)}{' '}
                      {bet.OverUnder === "Over" || bet.OverUnder === "Yes" ? (
                        <img
                          src="/images/icons/arrows.png"
                          alt="Over Arrow"
                          className="line-icon"
                        />
                      ) : (
                        <img
                          src="/images/icons/red_arrow.png"
                          alt="Under Arrow"
                          className="line-icon"
                        />
                      )}
                      
                      {displayStatLine(bet)}
                    </span>
                  )}
                  {isH2HBet && <span className="h2h-text">To Win</span>}
                  {isSpreadBet && (
                    <span className="spread-text">
                      <span className="team-id">{bet.TeamID} </span>
                      Spread:{" "}
                      <span className={`spread-value ${Number(bet.BetType) < 0 ? 'negative' : 'positive'}`}>
                        {Number(bet.BetType) < 0 ? '-' : '+'}{Math.abs(Number(bet.BetType)).toFixed(2)}
                      </span>
                    </span>
                  )}
                </div>
              </div>


              {/* Remove button with a small 'x' */}
              <button
                className="remove-button"
                onClick={() => onRemoveBet(bet.bet_id)}
              >
                ×
              </button>
            </div>
          );
        })
      )}
      {/* Multi Stats + Place Bet Button */}
      {selectedBets.length > 0 && (
        <>
          <div className="multi-stats">
            <p>Estimated Odds: <strong>{combinedOdds}</strong></p>
            <p>Multi Probability: <strong>{multiProb}%</strong></p>

            {/* Input fields for stake and odds taken */}
            <div className="stake-odds-inputs">
            <div className="input-group">
              <input
                type="number"
                min="1"
                step="1"
                value={stake}
                onChange={(e) => setStake(e.target.value)}
                placeholder="Stake"
              />
            </div>
            <div className="input-group">
              <input
                type="number"
                min="1"
                step="0.01"
                value={oddsTaken}
                onChange={(e) => setOddsTaken(e.target.value)}
                placeholder="Odds Taken"
              />
            </div>
            </div>
          </div>
          <button
            className="place-bet-btn"
            onClick={handlePlaceMultiBet}
            disabled={!stake || !oddsTaken}
          >
            Add Multi
          </button>
        </>
      )}
    </div>
  );
};

export default SelectedBetsSlip;