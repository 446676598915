import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import '../css/Performance/WinLossPieChart.css'; // Import the new CSS file

const WinLossPieChart = ({ data }) => {
    const winCount = data.filter(bet => bet.outcome === 'yes').length;
    const lossCount = data.filter(bet => bet.outcome === 'no').length;

    const chartData = [
        { name: 'Wins', value: winCount },
        { name: 'Losses', value: lossCount },
    ];

    const COLORS = ['#1CA733', '#ff7f7f']; // Green for wins, red for losses

    return (
        <div className="win-loss-container">
            <div className="chart-header">
                <h2 className="win-loss-title">Win/Loss Ratio</h2>
            </div>
            <div className="chart-content">
                <PieChart width={300} height={300}>
                    <Pie
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, value }) => `${name}: ${value}`}
                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index]} />
                        ))}
                    </Pie>
                    <Tooltip
                        wrapperStyle={{
                            backgroundColor: '#333',
                            color: '#fff',
                            borderRadius: '0px',
                            fontSize: '0.8rem',
                            border: 'none',
                        }}
                    />
                    <Legend
                        formatter={(value, entry) => (
                            <span style={{ color: entry.color, fontSize: '0.9rem', fontWeight: '500' }}>
                                {value}
                            </span>
                        )}
                        verticalAlign="bottom"
                        align="center"
                    />
                </PieChart>
            </div>
        </div>
    );
};

export default WinLossPieChart;