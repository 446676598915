import axios from 'axios';
import React, { useEffect, useState } from 'react';
import GameGraph from './graphs/gameGraph'; // Import the GameGraph component
import GamePickLayout from './Tile_layout/GamePickLayout';
import PlayerPickLayout from './Tile_layout/PlayerPickLayout';
import '../css/MultiTile.css';

const MultiTile = ({ gameId, data, onExpand, index, expandedIndex, selectedSport, selectedBets, setSelectedBets }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [statsData, setStatsData] = useState({});
    const [loading, setLoading] = useState(false);
    const [gameStats, setGameStats] = useState(null); // For storing last 5 games stats
    const [venueStats, setVenueStats] = useState(null); // For storing venue performance stats
    const [weatherStats, setWeatherStats] = useState(null); // For storing weather performance stats
    const [error, setError] = useState(null); // For storing errors
    const [selectedGraphType, setSelectedGraphType] = useState('games');
    // console.log(data);


    // If the object has HomeTeam/AwayTeam, treat it as a "game pick"
    const isGamePick = data.TeamID != null;

    // If the object has a PlayerName (or some other unique field), treat it as a "player pick"
    const isPlayerPick = data.PlayerName != null;

    
    useEffect(() => {
        setIsSelected(selectedBets.has(data.bet_id)); // Update state if bet is in `selectedBets`
    }, [selectedBets, data.bet_id]); // Runs when `selectedBets` changes

    const sportLogos = {
        AFL: "/images/AFL/afl.png",
        NRL: "/images/NRL/NRL New.png",
    };
    

    const handleGraphTypeChange = (type) => {
        setSelectedGraphType(type);
    };

    const statsConfig = {
        games: 'Team Performance (Last 5 Games)',
        venue: 'Venue Performance (Last 5 Games)',
        weather: 'Weather Performance (Last 5 Games)',
    };

    const resolvedGameId = data.GameID;

    // console.log('Fetching stats for GameID:', resolvedGameId);

    const fetchStatsData = async (type, gameID) => {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/analytics/stats?game_id=${gameID}&type=${type}`; // Unified API URL

        try {
            const response = await axios.get(apiUrl);
            return response.data; // Return the data
        } catch (err) {
            setError(`Failed to fetch ${type} stats: ${err.message}`);
            return null; // Gracefully handle errors
        }
    };

    const fetchAllData = async () => {
        try {
            const gameData = await fetchStatsData('games', resolvedGameId);
            const venueData = await fetchStatsData('venue', resolvedGameId);
            const weatherData = await fetchStatsData('weather', resolvedGameId);

            setStatsData({
                games: gameData || [],
                venue: venueData || [],
                weather: weatherData || [],
            });

            setGameStats(gameData || []);
            setVenueStats(venueData || []);
            setWeatherStats(weatherData || []);

            setLoading(false); // Set loading to false after data is fetched
        } catch (err) {
            console.error('Failed to fetch data:', err);
            setError(true);
        }
    };

    useEffect(() => {
        if (resolvedGameId) {
            setLoading(true); // Start loading
            fetchAllData(); // Fetch data
        }
    }, [resolvedGameId]);


    // console.log('Game Stats:', gameStats);
    // console.log('Venue Stats:', venueStats);
    // console.log('Weather Stats:', weatherStats);
        
    
    const toggleExpand = () => {
        setIsExpanded((prev) => {
            const nextState = !prev;
    
            // Apply or remove the blur effect on the tiles-container
            const tileContainer = tileRef.current.closest('.tiles-container'); // Get the parent container
            if (tileContainer) {
                if (nextState) {
                    tileContainer.classList.add('toggle'); // Add class to blur other tiles
                } else {
                    tileContainer.classList.remove('toggle');
                }
            }
    
            return nextState;
        });
    };
    
    
    const toggleFlip = (event) => {
        if (event) {
            event.stopPropagation(); // Prevent triggering expand
        }
        setIsFlipped((prev) => !prev); // Toggle flip state
    };

    // Ref to the tile element to calculate its position on screen
    const tileRef = React.useRef();



    const handleButtonClick = async (event) => {
        event.stopPropagation();
    
        if (!isSelected) {
        // Expand tile if you want it expanded before adding
        if (!isExpanded) {
            setIsExpanded(true);
        }
        }
    };

    // Automatically unflip when tile is unexpanded
    useEffect(() => {
        if (!isExpanded) {
            setIsFlipped(false);
        }
    }, [isExpanded]);

    const handleSelectClick = (event) => {
        event.stopPropagation();
        setSelectedBets(); // or whatever function you want to call
      };
      


    return (
        <div
          ref={tileRef}
          className={`multitile ${isExpanded ? 'expanded' : ''} ${isFlipped ? 'flipped' : ''}`}
          onClick={toggleExpand}
        >
          {/* If not flipped, show the “front” layout */}
          {!isFlipped ? (
            <>
              {/* The header (sport icon, date, select button) can be shared for both picks */}
              <div className="tile-header">
                <div className="tile-header-left">
                  {/* Example: sport icon */}
                  <img
                    src={selectedSport === 'AFL' ? '/images/AFL/afl.png' : '/images/NRL/NRL New.png'}
                    alt="Sport Icon"
                    className="sport-icon"
                  />
                </div>
    
                <div className="tile-header-middle">
                  <div className="date-container">
                    <span className="game-date">
                      {data.GameDate
                        ? new Date(data.GameDate).toLocaleDateString('en-GB', {
                            weekday: 'short',
                            day: '2-digit',
                            month: 'short',
                          })
                        : 'N/A'}
                    </span>
                  </div>
                </div>
    
                <div className="tile-header-right">
                  <button
                    onClick={handleSelectClick}
                    className={`tile-button ${isSelected ? 'selected' : ''}`}
                  >
                    {isSelected ? '×' : '+'}
                  </button>
                </div>
              </div>
    
              {/* Conditionally render the “front” body: game or player */}
              {isGamePick && (
                <GamePickLayout
                  data={data}
                  isExpanded={isExpanded}
                  toggleFlip={toggleFlip}
                />
              )}
    
              {isPlayerPick && (
                <PlayerPickLayout
                  data={data}
                  isExpanded={isExpanded}
                  toggleFlip={toggleFlip}
                />
              )}
            </>
          ) : (
            // If flipped, show the “back” layout
            <div className="tile-back">
              {/* Example: game picks might show advanced stats, 
                  player picks might show a player stats graph */}
              <button onClick={toggleFlip} className="flip-back-button">
                &lt;
              </button>
              {isGamePick ? (
                <div>Game Graph or stats here</div>
              ) : (
                <div>Player Graph or stats here</div>
              )}
            </div>
          )}
        </div>
      );
    }
    
    export default MultiTile;