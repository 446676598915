import React, { useState, useEffect } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  Tooltip,
} from "recharts";
import "../css/Performance/RadarChart.css";

const StatTypeRadarChart = ({ data }) => {
  const [chartSize, setChartSize] = useState({ width: 900, height: 500 });

  useEffect(() => {
    const updateChartSize = () => {
      if (window.innerWidth <= 768) {
        setChartSize({ width: 300, height: 300 }); // Mobile sizes
      } else if (window.innerWidth <= 1024) {
        setChartSize({ width: 600, height: 400 }); // Tablet sizes
      } else {
        setChartSize({ width: 900, height: 500 }); // Desktop sizes
      }
    };

    updateChartSize(); // Set initial size
    window.addEventListener("resize", updateChartSize); // Listen for window resize
    return () => window.removeEventListener("resize", updateChartSize); // Cleanup
  }, []);

  const processedData = Object.values(
    data.reduce((acc, bet) => {
      const { stat_type, outcome } = bet;
      if (!stat_type) return acc;
  
      // Initialize the accumulator entry if it doesn't exist
      if (!acc[stat_type]) {
        acc[stat_type] = { statType: stat_type, totalBets: 0, totalWins: 0 };
      }
  
      // Skip incrementing if outcome is 'pending' or empty
      if (outcome && outcome !== 'pending') {
        acc[stat_type].totalBets += 1;
        if (outcome === 'yes') {
          acc[stat_type].totalWins += 1;
        }
      }
  
      return acc;
    }, {})
  );

  if (processedData.length === 0) {
    return (
      <p style={{ color: "#ffffff", textAlign: "center" }}>
        No data available to display.
      </p>
    );
  }

  return (
    <div className="radar-chart-container">
      <h2>Stat Type Performance</h2>
      <RadarChart
        cx="50%"
        cy="50%"
        outerRadius="65%"
        width={chartSize.width}
        height={chartSize.height}
        data={processedData}
      >
        <PolarGrid />
        <PolarAngleAxis
          dataKey="statType"
          tick={{
            fill: "#ffffff",
            fontSize: 12,
            angle: 0,
          }}
          tickFormatter={(label) =>
            label.replace(/_/g, " ").length > 30
              ? `${label.replace(/_/g, " ").substring(0, 22)}...`
              : label.replace(/_/g, " ")
          }
        />
        <PolarRadiusAxis tick={{ fill: "#b0b0b0" }} />
        <Radar
          name="Total Bets"
          dataKey="totalBets"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
        <Radar
          name="Total Wins"
          dataKey="totalWins"
          stroke="#82ca9d"
          fill="#82ca9d"
          fillOpacity={0.6}
        />
        <Tooltip />
        <Legend />
      </RadarChart>
    </div>
  );
};

export default StatTypeRadarChart;