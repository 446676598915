import React, { useState, useEffect } from 'react';
import Accordion from '../components/Accordion';
import MultiTile from '../components/MultiTile';
import SelectedBetsSlip from '../components/SelectedBetsSlip';
import GenerateModal from '../components/GenerateModal';
import '../css/MultiCreatorPage.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function MultiCreatorPage() {
  // Store picks separately (or filter from a single array)
  const [nrlGamePicks, setNrlGamePicks] = useState([]);
  const [nrlPlayerPicks, setNrlPlayerPicks] = useState([]);
  const [aflGamePicks, setAflGamePicks] = useState([]);
  const [aflPlayerPicks, setAflPlayerPicks] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);   

  // A Set of selected bet IDs
  const [selectedBetIds, setSelectedBetIds] = useState(new Set());
  // An array of selected bet objects
  const [selectedBetObjects, setSelectedBetObjects] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);


  // Fetch NRL Game Picks
  const fetchNrlGamePicks = async (filters = {}) => {
    try {
      setLoading(true);
      const {
        game = '',
        date = '',
        minAdvantage = '',
        maxAdvantage = '',
        minOdds = '',
        maxOdds = ''
      } = filters;
  
      let homeTeamID = '';
      let awayTeamID = '';
      if (game.includes(' vs ')) {
        [homeTeamID, awayTeamID] = game.split(' vs ');
      }
  
      const params = new URLSearchParams({
        selected_date: date,
        home_team_id: homeTeamID,
        away_team_id: awayTeamID,
        min_advantage: minAdvantage ? parseFloat(minAdvantage) : '',
        max_advantage: maxAdvantage ? parseFloat(maxAdvantage) : '',
        min_odds: minOdds ? parseFloat(minOdds) : '',
        max_odds: maxOdds ? parseFloat(maxOdds) : '',
      });
  
      const response = await fetch(
        `${BACKEND_URL}/betting/nrl-game-picks?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      if (!response.ok) {
        // handle errors, e.g., 403 => navigate('/plans') ...
        throw new Error(`Failed to fetch NRL Game Picks: ${response.statusText}`);
      }
  
      const data = await response.json();
      setNrlGamePicks(data || []);
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch NRL Player Picks
  const fetchNrlPlayerPicks = async (filters = {}) => {
    try {
      setLoading(true);
      // Similar approach as above, but call the NRL player picks endpoint
      // e.g., /betting/nrl-player-picks
      const params = new URLSearchParams({ /* ... */ });
      // ...
      const response = await fetch(`${BACKEND_URL}/betting/nrl-player-picks?${params}`, { /* ... */ });
      // ...
      const data = await response.json();
      setNrlPlayerPicks(data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  // Fetch AFL Game Picks
  const fetchAflGamePicks = async (filters = {}) => {
    // e.g. /betting/ranked-team-bets or whichever endpoint you use for AFL

    try {
        setLoading(true);
        const {
          game = '',
          date = '',
          minAdvantage = '',
          maxAdvantage = '',
          minOdds = '',
          maxOdds = ''
        } = filters;
    
        let homeTeamID = '';
        let awayTeamID = '';
        if (game.includes(' vs ')) {
          [homeTeamID, awayTeamID] = game.split(' vs ');
        }
    
        const params = new URLSearchParams({
          selected_date: date,
          home_team_id: homeTeamID,
          away_team_id: awayTeamID,
          min_advantage: minAdvantage ? parseFloat(minAdvantage) : '',
          max_advantage: maxAdvantage ? parseFloat(maxAdvantage) : '',
          min_odds: minOdds ? parseFloat(minOdds) : '',
          max_odds: maxOdds ? parseFloat(maxOdds) : '',
        });
    
        const response = await fetch(
          `${BACKEND_URL}/betting/ranked-team-bets?${params.toString()}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
    
        if (!response.ok) {
          // handle errors, e.g., 403 => navigate('/plans') ...
          throw new Error(`Failed to fetch AFL Game Picks: ${response.statusText}`);
        }
    
        const data = await response.json();
        setAflGamePicks(data || []); 
      } catch (err) {
        setError(err.message);
        console.error(err);
      } finally {
        setLoading(false);
      }
  };

  // Fetch AFL Player Picks
  const fetchAflPlayerPicks = async (filters = {}) => {
    try {
      setLoading(true);
      const params = new URLSearchParams({ /* ... */ });
      const response = await fetch(`${BACKEND_URL}/betting/player-best-odds?${params}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch AFL Player Picks: ${response.statusText}`);
      }
      const data = await response.json();
      console.log('AFL Player Picks Data:', data);
      // If data is not an array, extract the array property (adjust this based on your API response)
      const picksArray = Array.isArray(data) ? data : data.data || [];
      setAflPlayerPicks(picksArray);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };



  function filterPicks(formData, allPicks) {
    const {
      sport,
      sportsbook,
      type,
      selectedGame,
      markets,
      excludeMarkets,
    } = formData;
  
    return allPicks.filter((pick) => {
      // 1. Filter by sport if user didn’t choose "All"
      if (sport !== 'ALL' && pick.sport !== sport) {
        return false;
      }
  
      // 2. Filter by sportsbook if user didn’t choose "All"
      //    The data field is "BookType", so compare to that:
      if (sportsbook !== 'ALL' && pick.BookType !== sportsbook) {
        return false;
      }
  
      if (type === 'High Risk' && pick.Model_probability < 65) {
        return false;
      } else if (type === 'Balanced' && pick.Model_probability < 75) {
        return false;
      } else if (type === 'Low Risk' && pick.Model_probability < 90) {
        return false;
      }

      if (selectedGame !== 'ALL' && pick.GameID !== selectedGame) {
        return false;
      }

      if (markets !== 'ALL' && (pick.BetStatType !== markets && pick.BetCategory !== markets)) {
        return false;
      }

      if (excludeMarkets !== 'None' && (pick.BetStatType === excludeMarkets || pick.BetCategory === excludeMarkets)) {
        return false;
      }
  
      return true; // pass all checks
    });
  }

  function selectMultiBets(filteredPicks, legs) {
    // 1. Convert Model_probability to a decimal and calculate EV as before
    const picksWithEv = filteredPicks.map((p) => {
      const probabilityDecimal = (p.Model_probability || 0) / 100; 
      const oddsDecimal = p.Odds || 1.0;
      const ev = (probabilityDecimal * oddsDecimal) - 1;
      return {
        ...p,
        probability: probabilityDecimal,
        odds: oddsDecimal,
        ev,
      };
    });
    
    // 2. Filter out bets based on EV being negative
    let filteredByEv = picksWithEv.filter((p) => p.ev > 0);
    
    // 3. Additional filtering criteria:
    // Filter out bets with odds lower than a threshold (e.g., <1.1)
    //filteredByEv = filteredByEv.filter((p) => p.odds >= 1.1);
  
    // Filter out bets with a probability lower than a threshold (e.g., <0.50)
    filteredByEv = filteredByEv.filter((p) => p.probability >= 0.50);
  
    // Optionally, filter by a minimum EV value if needed (e.g., ev must be at least 0.1)
    filteredByEv = filteredByEv.filter((p) => p.ev >= 0.05);
    
    // 4. Shuffle the filtered array (Fisher-Yates shuffle)
    for (let i = filteredByEv.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [filteredByEv[i], filteredByEv[j]] = [filteredByEv[j], filteredByEv[i]];
    }
    
    // 5. After shuffling, take the first N items
    return filteredByEv.slice(0, legs);
  }

  function calculateMultiStats(selectedBets) {
    // Combined Probability = product of individual probabilities
    const combinedProbability = selectedBets.reduce(
      (acc, bet) => acc * bet.probability,
      1
    );
  
    // Combined Odds = product of individual odds
    const combinedOdds = selectedBets.reduce(
      (acc, bet) => acc * bet.odds,
      1
    );
  
    return { combinedProbability, combinedOdds };
  }

  const handleGenerateMultiFromModal = (formData) => {
    const allPicks = [
      ...aflGamePicks.map((p) => ({ ...p, sport: 'AFL' })),
      ...aflPlayerPicks.map((p) => ({ ...p, sport: 'AFL' })),
      ...nrlGamePicks.map((p) => ({ ...p, sport: 'NRL' })),
      ...nrlPlayerPicks.map((p) => ({ ...p, sport: 'NRL' })),
    ];

    console.log("ALL Picks", allPicks)
  
    // 1. Filter
    const filtered = filterPicks(formData, allPicks);
  
    // 2. Select bets (randomly or by EV)
    const selected = selectMultiBets(filtered, formData.legs);
  
    // 3. Calculate combined stats
    const { combinedProbability, combinedOdds } = calculateMultiStats(selected);
  
    console.log('Generated multi:', selected);
    console.log('Combined Probability:', combinedProbability);
    console.log('Combined Odds:', combinedOdds);
  
    // 4. CLEAR existing bets, then set new picks
    //    (No need to append to the old arrays, just overwrite them)
    setSelectedBetIds(new Set(selected.map((b) => b.bet_id)));
    setSelectedBetObjects(selected);
  };

  // Clear the bet slip (selected bets)
  const clearSelectedBets = () => {
    setSelectedBetIds(new Set());
    setSelectedBetObjects([]);
  };


  // Handler to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Handler to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  // fetch all api routes for bets 
  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([
        fetchNrlGamePicks(),
        fetchNrlPlayerPicks(),
        fetchAflGamePicks(),
        fetchAflPlayerPicks(),
      ]);
    };
  
    fetchAllData();
  }, []);

  // Called when user selects a bet
  const handleSelectBet = (bet) => {
    setSelectedBetIds((prev) => new Set(prev).add(bet.bet_id));
    setSelectedBetObjects((prev) => {
      if (prev.find((b) => b.bet_id === bet.bet_id)) return prev;
      return [...prev, bet];
    });
  };

  // Called when user removes a bet
  const handleRemoveBet = (betId) => {
    setSelectedBetIds((prev) => {
      const newSet = new Set(prev);
      newSet.delete(betId);
      return newSet;
    });
    setSelectedBetObjects((prev) => prev.filter((b) => b.bet_id !== betId));
  };

  return (
    <div className="multi-creator-page">
      {/* Left column: Accordions for each category */}
      <div className="left-column">
        <Accordion title="NRL Game Picks">
            <div className="tiles-container">
            {nrlGamePicks.map((pick) => (
            <MultiTile
                key={pick.bet_id}
                data={pick}
                selectedBets={selectedBetIds}
                setSelectedBets={() => handleSelectBet(pick)}
            />
            ))}
            </div>
        </Accordion>

        <Accordion title="NRL Player Picks">
            <div className="tiles-container">
            {nrlPlayerPicks.map((pick) => (
            <MultiTile
                key={pick.bet_id}
                data={pick}
                selectedBets={selectedBetIds}
                setSelectedBets={() => handleSelectBet(pick)}
            />
            ))}
            </div>
        </Accordion>

        <Accordion title="AFL Game Picks">
            <div className="tiles-container">
            {aflGamePicks.map((pick) => (
            <MultiTile
                key={pick.bet_id}
                data={pick}
                selectedBets={selectedBetIds}
                setSelectedBets={() => handleSelectBet(pick)}
            />
            ))}
            </div>
        </Accordion>

        <Accordion title="AFL Player Picks">
            <div className="tiles-container">
            {aflPlayerPicks.map((pick) => (
            <MultiTile
                key={pick.bet_id}
                data={pick}
                selectedBets={selectedBetIds}
                setSelectedBets={() => handleSelectBet(pick)}
            />
            ))}
            </div>
        </Accordion>
        </div>


        {/* Floating Toggle Button for Mobile */}
        <button className="drawer-toggle-btn" onClick={toggleDrawer}>
          {drawerOpen ? '×' : '+'}
        </button>


      {/* Right column: Bet Slip + Button */}
      <div className={`right-column ${drawerOpen ? 'open' : ''}`}>

        {/* Render the selected bets */}
        <SelectedBetsSlip
          selectedBets={selectedBetObjects}
          onRemoveBet={handleRemoveBet}
          onMultiPlaced={clearSelectedBets} 
        />

        {/* "Generate Multi Bet" button at the top */}
        <div className="generate-button-container">
          <button className="generate-multi-btn" onClick={handleOpenModal}>
            Generate Multi
          </button>
        </div>
        
      </div>

      {/* Modal */}
      <GenerateModal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
        onGenerateMulti={handleGenerateMultiFromModal}
      />
    </div>
  );
}

export default MultiCreatorPage;